<div [ngClass]="{'landing-page': true,'show-mobile-menu': showMobileMenu}">
    <div class="mobile-menu" (click)="$event.stopPropagation()">
        <a (click)="scrollTo('#home')" href="javascript:;" class="logo-mobile">
            <span></span>
        </a>
        <ul class="navbar-nav">
            <li class="nav-item"><a (click)="scrollTo('#features')" href="javascript:;">WHAT WE OFFER</a></li>
            <li class="nav-item"><a (click)="scrollTo('#layouts')" href="javascript:;">HOW TO USE</a></li>
            <li class="nav-item"><a (click)="scrollTo('#components')" href="javascript:;">PACKAGES</a></li>
           <!-- <li class="nav-item"><a (click)="scrollTo('#tab2')" href="javascript:;">PACKAGES</a></li> -->
            <li class="nav-item"><a (click)="scrollTo('#apps')" href="javascript:;">BLOG</a></li>
            <li class="nav-item"><a (click)="scrollTo('#themes')" href="javascript:;">CONTACT</a></li>
            <li class="nav-item">
                <div class="separator"></div>
            </li>
            <li class="nav-item text-center">
                <a class="btn btn-outline-primary btn-sm mobile-menu-cta" [href]="loginUrl">SIGN IN</a>
            </li>
        </ul>

    </div>

    <div class="main-container">
        <ngx-headroom class="landing-page-nav">
            <nav>
                <div class="container d-flex align-items-center justify-content-between">
                    <a class="navbar-logo pull-left" (click)="scrollTo('#home')" href="javascript:;">
                        <span class="white"></span>
                        <span class="dark"></span>
                        <!-- <img src="../../../assets/img/logo/logo.png" style="height: 40px; width: 160px;"> -->
                    </a>
                    <ul class="navbar-nav d-none d-lg-flex flex-row">
                        <li class="nav-item"><a (click)="scrollTo('#features')" href="javascript:;">WHAT WE OFFER</a>
                        </li>
                        <li class="nav-item"><a (click)="scrollTo('#layouts')" href="javascript:;">HOW TO USE</a></li>
                        <li class="nav-item"><a (click)="scrollTo('#components')" href="javascript:;">PACKAGES</a>
                        </li>
                        <!-- <li class="nav-item"><a (click)="scrollTo('#tab2')" href="javascript:;">PACKAGES</a>
                        </li> -->
                        <li class="nav-item"><a (click)="scrollTo('#apps')" href="javascript:;">BLOG</a></li>
                        <li class="nav-item"><a (click)="scrollTo('#themes')" href="javascript:;">CONTACT</a></li>
                        <li class="nav-item pl-4">
                            <a class="btn btn-outline-semi-light btn-sm pr-4 pl-4" [href]="loginUrl">SIGN IN</a>
                        </li>
                    </ul>
                    <span class="mobile-menu-button" (click)="showMobileMenu=!showMobileMenu; $event.stopPropagation()">
                        <i class="simple-icon-menu"></i>
                    </span>
                </div>
            </nav>
        </ngx-headroom>


        <div class="content-container" id="home">

            <div class="section home">
                <div class="container">
                    <div class="row home-row">
                        <div class="col-12 d-block d-md-none">
                            <a [href]="adminRoot" target="_blank">
                                <img alt="mobile hero" class="mobile-hero"
                                    src="/assets/img/landing-page/home-hero-mobile.png" />
                            </a>
                        </div>

                        <div class="col-12 col-xl-4 col-lg-5 col-md-6">
                            <div class="home-text">
                                <div class="display-1">BOSS SIGNATURE</div>
                                <p class="white mb-5">
                                    Complete and easy e-signature solution at your fingertips.
                                </p>
                                <!-- [href]="adminRoot" target="_blank" -->
                                <a class="btn btn-light btn-xl mr-2 mb-2" [href]="RegisterUrl">
                                    REGISTER NOW <i class="simple-icon-arrow-right"></i>
                                </a>
                            </div>
                        </div>
                        <div class="col-12 col-xl-7 offset-xl-1 col-lg-7 col-md-6  d-none d-md-block">
                            <a [href]="adminRoot" target="_blank">
                                <img alt="hero" src="/assets/img/landing-page/home-hero.png" />
                            </a>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 p-0">
                            <div class="home-carousel">
                                <app-glide [settings]="slideSettings">
                                    <div class="card" *ngFor="let f of slideItems">
                                        <div class="card-body text-center">
                                            <div>
                                                <i class="{{f.icon + ' large-icon'}}"></i>
                                                <h5 class="mb-3 font-weight-semibold">{{f.title}}</h5>
                                            </div>
                                            <div>
                                                <p class="detail-text">{{f.detail}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </app-glide>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <a class="btn btn-circle btn-outline-semi-light hero-circle-button"
                            (click)="scrollTo('#features')">
                            <i class="simple-icon-arrow-down"></i>
                        </a>
                    </div>
                </div>
            </div>

            <div class="section background">
                <div class="container" id="layouts">
                    <div class="row">
                        <div class="col-12 offset-0 col-lg-8 offset-lg-2 text-center">
                            <h1>HOW TO USE</h1>
                            <p>
                                We did our best to create layouts for various needs that developers might have and best
                                experience
                                for users.
                                <br>
                                They are clean and slick. They function well and look good at the same time.

                            </p>
                        </div>
                    </div>

                    <div class="row pt-5">
                        <div class="col-12 col-xs-6 col-sm-6 col-md-4 col-lg-3 mb-5" *ngFor="let l of layouts">
                            <img class="img-fluid border-radius depth-2 mb-3 semi-rounded" [src]="l.img">
                            <h4 class="text-center">{{l.title}}</h4>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="section mb-0">
                <div class="container" id="components">
                    <div class="row mb-5">
                        <div class="col-12 text-center">
                            <h1>PACKAGES</h1>
                            <div class="row">
                                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12" *ngFor="let price of price">
                                    <div class="card"
                                        style="margin-bottom: 5px;box-shadow: 0 2px 15px rgb(0 0 0 / 10%), 0 1px 6px rgb(0 0 0 / 10%) !important">
                                        <div class="card-body pt-5 pb-5 d-flex flex-lg-column flex-md-row flex-sm-row flex-column"
                                            style="padding: 10px !important;">
                                            <div class="price-top-part">
                                                <i [class]="'large-icon '+ price.icon"></i>
                                                <h5 class=" mb-0 font-weight-semibold color-theme-1 mb-1">
                                                    {{price.PackageName}}</h5>
                                                <p class="text-medium mb-2 text-default">${{price.PerUser_PerMonth_fee}}/mo - ${{price.PerUser_PerYear_fee}}/year</p>
                                                <p class="text-muted text-small">User: {{price.Users}}</p>
                                            </div>
                                            <div
                                                class="pl-3 pr-3 pb-0 d-flex price-feature-list flex-column flex-grow-1">
                                                <ul class="list-unstyled" style="height: 200px;">
                                                    <li *ngFor="let feature of price.features">
                                                        <p class="mb-0">{{feature}}</p>
                                                    </li>
                                                </ul>
                                                <button *ngIf="price.PackageID === 1" type="button" class="btn btn-primary" (click)="nevigateTO()">
                                                    TRY NOW
                                                </button>
                                                <button *ngIf="price.PackageID !== 1" type="button" class="btn btn-primary" (click)="buyNow(price)">
                                                    BUY NOW
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->



                  
                <div class="container"  class="display:flex;" class="text-center"  >
                  <!-- <div  class="tab-slider--nav">
                    <ul  class="tab-slider--tabs" style="display:flex;">
                      <li  style="color: #fff; background-color: #07579d; width: 100px; border-radius: 12px; height: 30px; align-items: center; margin-left: 42%;list-style: none; margin-right: 10px;"
                        class="tab-slider--trigger" 
                        [class.active]="viewMode == 'tab1'"
                        rel="tab1" 
                        (click)="binder()">
                         Basics
                      </li>
                      <li  style="color: #fff; background-color: #07579d;width: 100px; border-radius: 12px; height: 30px; align-items: center; text-decoration: none;list-style: none;" 
                        class="tab-slider--trigger"
                        [class.active]="viewMode == 'tab2'"
                        rel="tab2"
                        (click)="realtor()" >
                        (click)="viewMode = 'tab2'"
                        (click)="scrollTo1('#viewMode')"
                        Realtors 
                      </li>
                    </ul>
                  </div> -->
                  <div class="tab-slider--container" [ngSwitch]="viewMode">
                    <div id="tab1" class="tab-slider--body" *ngSwitchCase="'tab1'">                
                      <!-- <p>
                      All Packages related to Basics Packages
                      </p> -->
                      <div class="section mb-0">
                        <div class="container" id="components">
                            <div class="row mb-5">
                                <div class="col-12 text-center">  
                                    <h2>Packages</h2>
                                    <div class="row">
                                        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12" *ngFor="let price of price">
                                            <div class="card"
                                                style="margin-bottom: 5px;box-shadow: 0 2px 15px rgb(0 0 0 / 10%), 0 1px 6px rgb(0 0 0 / 10%) !important">
                                                <div class="card-body pt-5 pb-5 d-flex flex-lg-column flex-md-row flex-sm-row flex-column"
                                                    style="padding: 10px !important;">
                                                    <div class="price-top-part">
                                                        <i [class]="'large-icon '+ price.icon"></i>
                                                        <h5 class=" mb-0 font-weight-semibold color-theme-1 mb-1">
                                                            {{price.PackageName}}</h5>
                                                        <p class="text-medium mb-2 text-default">${{price.PerUser_PerMonth_fee}}/mo - ${{price.PerUser_PerYear_fee}}/year</p>
                                                        <p class="text-muted text-small">User: {{price.Users}}</p> 
                                                        <!-- <p class="text-muted text-small">User: {{price.Users}}</p>  -->
                                                        <!-- <h5 class=" mb-0 font-weight-semibold color-theme-1 mb-1">
                                                            {{price.PackageName}}</h5> -->
                                                    </div>
                                                    <div
                                                        class="pl-3 pr-3 pb-0 d-flex price-feature-list flex-column flex-grow-1">
                                                        <ul class="list-unstyled" style="height: 200px;">
                                                            <li *ngFor="let feature of price.features">
                                                                <p class="mb-0">{{feature}}</p>
                                                            </li>
                                                        </ul>
                                                        <button *ngIf="price.PackageID === 1" type="button" class="btn btn-primary" (click)="nevigateTO()">
                                                            TRY NOW
                                                        </button>
                                                        <button *ngIf="price.PackageID !== 1" type="button" class="btn btn-primary" (click)="buyNow(price)">
                                                            BUY NOW
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div id="tab2" class="tab-slider--body"  *ngSwitchCase="'tab2'">
                      <!-- <p>
                        All Packages related to Realtors Packages
                        </p> -->
                      <div class="section mb-0">
                        <div class="container" id="components">
                            <div class="row mb-5">
                                <div class="col-12 text-center">
                                    <h2>Packages</h2>
                                    <div  class="tab-slider--nav" class="pe-auto" >
                                        <ul  class="tab-slider--tabs" style="display:flex;">
                                          <li  style="color: #fff; background-color: #07579d; width: 100px; border-radius: 12px; height: 30px; align-items: center; margin-left: 30%;list-style: none; margin-right: 20px;padding: 0% 10% 0% 8%; box-sizing: border-box; margin-bottom: 20px; cursor: pointer;"
                                            class="tab-slider--trigger" 
                                            [class.active]="viewMode == 'tab1'"
                                            rel="tab1" 
                                            (click)="binder()">
                                             Basics
                                          </li>
                                          <li  style="color: #fff; background-color: #07579d;width: 100px; border-radius: 12px; height: 30px; align-items: center; text-decoration: none;list-style: none; padding: 0% 10% 0% 8%;cursor: pointer; " 
                                            class="tab-slider--trigger"
                                            [class.active]="viewMode == 'tab2'"
                                            rel="tab2"
                                            (click)="realtor()" >
                                            <!-- (click)="viewMode = 'tab2'"
                                            (click)="scrollTo1('#viewMode')" -->
                                            Realtors 
                                          </li>
                                        </ul>
                                      </div>
                                    <div class="row">
                                        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12" *ngFor="let price of price">
                                            <div class="card"
                                                style="margin-bottom: 5px;box-shadow: 0 2px 15px rgb(0 0 0 / 10%), 0 1px 6px rgb(0 0 0 / 10%) !important">
                                                <div class="card-body pt-5 pb-5 d-flex flex-lg-column flex-md-row flex-sm-row flex-column"
                                                    style="padding: 10px !important;">
                                                    <div class="price-top-part">
                                                        <i [class]="'large-icon '+ price.icon"></i>
                                                        <h5 class=" mb-0 font-weight-semibold color-theme-1 mb-1">
                                                            {{price.PackageName}}</h5>
                                                        <p class="text-medium mb-2 text-default">${{price.PerUser_PerMonth_fee}}/mo - ${{price.PerUser_PerYear_fee}}/year</p>
                                                        <p class="text-muted text-small">User: {{price.Users}}</p>
                                                        <p class="text-muted text-small">{{price.P_Line1}}</p>
                                                        <p class="text-muted text-small">{{price.P_Line2}}</p>
                                                        <p class="text-muted text-small">{{price.P_Line3}}</p>
                                                        <p class="text-muted text-small">{{price.P_Line4}}</p>
                                                        <p class="text-muted text-small">{{price.P_Line5}}</p>
                                                        <p class="text-muted text-small">{{price.P_Line6}}</p>
                                                        <p class="text-muted text-small">{{price.P_Line7}}</p>
                                                        <p class="text-muted text-small">{{price.P_Line8}}</p>
                                                    </div>
                                                    <!-- <div  *ngFor="let packages of packages">
                                                        <p  class="text-muted text-small">Detail:{{packages.Detail}} </p>
                                                    </div> -->
                                                    <!-- <div class="price-top-part">
                                                        <i [class]="'large-icon '+ price.icon"></i>
                                                        <h5 class=" mb-0 font-weight-semibold color-theme-1 mb-1">
                                                            {{price.PackageName}}</h5>
                                                        <p class="text-medium mb-2 text-default">${{price.PerUser_PerMonth_fee}}/mo - ${{price.PerUser_PerYear_fee}}/year</p>
                                                        <p class="text-muted text-small">User: {{price.Users}}</p>
                                                    </div> -->
                                        
                                                    <div
                                                        class="pl-3 pr-3 pb-0 d-flex price-feature-list flex-column flex-grow-1">
                                                        <ul class="list-unstyled" style="height: 25px;">
                                                            <li *ngFor="let feature of price.features">
                                                                <p class="mb-0">{{feature}}</p>
                                                            </li>
                                                        </ul>
                                                        <button *ngIf="price.PackageID === 1" type="button" class="btn btn-primary" (click)="nevigateTO()">
                                                            TRY NOW
                                                        </button>
                                                        <button *ngIf="price.PackageID !== 1" type="button" class="btn btn-primary" (click)="buyNow(price)">
                                                            BUY NOW
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            

            <div class="section">
                <div class="container" id="apps">
                    <div class="row">
                        <div class="col-12 offset-0 col-lg-8 offset-lg-2 text-center mb-4">
                            <h1>BLOG</h1>
                        </div>
                    </div>
                    <div class="row screenshots">
                        <div class="col-12 text-center mb-4">
                            <div class="home-carousel">
                                <app-glide [settings]="slideSettings">
                                    <div class="card"
                                        style="box-shadow: 0 2px 15px rgb(0 0 0 / 10%), 0 1px 6px rgb(0 0 0 / 10%) !important"
                                        *ngFor="let f of slideItems">
                                        <div class="card-body text-center">
                                            <div>
                                                <i class="{{f.icon + ' large-icon'}}"></i>
                                                <h5 class="mb-3 font-weight-semibold">{{f.title}}</h5>
                                            </div>
                                            <div>
                                                <p class="detail-text">{{f.detail}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </app-glide>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="section mb-0">
                <div class="container" id="themes">
                    <div class="row mb-5">
                        <div class="col-12 offset-0 col-lg-8 offset-lg-2 text-center">
                            <h1>CONTACT</h1>
                        </div>
                    </div>
                    <div class="row mb-5">
                        <div class="col-6">
                            <img src="assets/img/map.jpg" style="width: 500px;">
                        </div>
                        <div class="col-6">
                            <div class="row">
                                <div class="col-lg-12">
                                    <label style="margin-bottom: 0px;">Name</label>
                                    <input type="text" id="name" class="form-control"
                                        style="border-radius: 8px;margin-bottom: 3px;">
                                </div>
                                <div class="col-lg-12">
                                    <label style="margin-bottom: 0px;">Email</label>
                                    <input type="text" id="Email" class="form-control"
                                        style="border-radius: 8px;margin-bottom: 3px;">
                                </div>
                                <div class="col-lg-12">
                                    <label style="margin-bottom: 0px;">Subject</label>
                                    <input type="text" id="name" class="form-control"
                                        style="border-radius: 8px;margin-bottom: 3px;">
                                </div>
                                <div class="" style="width: 97%;margin-top: 6px;">
                                    <button style="float: right;" type="button" class="btn btn-primary">
                                        SUBMIT
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="section background background-no-bottom mb-0 pb-0">
                <div class="container">
                    <div class="row">
                        <div class="col-12 offset-0 col-lg-8 offset-lg-2 text-center">
                            <h1>Enjoying so Far?</h1>
                            <p>
                                Purchase eBoss Signature to get a fresh start with your Documents.
                            </p>
                        </div>
                        <div class="col-12 offset-0 col-lg-6 offset-lg-3 newsletter-input-container">
                            <div class="text-center mb-3">
                                <a class="btn btn-secondary btn-xl">BUY NOW</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="section footer mb-0">
                <div class="container">
                    <div class="row footer-row">
                        <div class="col-12 text-right">
                            <a class="btn btn-circle btn-outline-semi-light footer-circle-button"
                                (click)="scrollTo('#home')" href="javascript:;"><i class="simple-icon-arrow-up"></i></a>
                        </div>
                        <div class="col-12 text-center footer-content">
                            <a (click)="scrollTo('#home')" href="javascript:;">
                                <img class="footer-logo" alt="footer logo" src="/assets/logos/white-full.svg" />
                            </a>
                        </div>
                    </div>
                </div>
                <div class="container copyright pt-5 pb-5">
                    <div class="row">
                        <div class="col-12"></div>
                        <div class="col-12 text-center">
                            <p class="mb-0">2020 © ColoredStrategies</p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>