<!-- <div id="app-container"> -->
<!-- <app-topnav></app-topnav> -->
<!-- <main style="    margin-left: 10px !important;
    margin-right: 10px !important;"> -->
<div class="row">
    <div class="col-lg-4">
        <div class="card">
            <div class="card-body" style="height: 190px;">
                <div class="row">
                    <div class="col-lg-5">
                        <img src="assets/img/profiles/user.png" style="width: inherit;">
                    </div>
                    <div class="col-lg-7">
                        <img [src]="imageSignature" onerror="this.src='assets/img/profiles/sign.png'" (click)="modalOpen()" style="width: inherit;padding-top: 10px;">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-8" *ngIf="firstRow.length > 0">
        <div class="icon-cards-row">
            <app-glide #carousel [settings]="{
            type: 'carousel',
            perView: 4,
            hideNav: true
        }">
                <div *ngFor="let item of firstRow" class="glide__slide">
                    <div class="icon-row-item" style="width: inherit;">
                        <div class="card">
                            <div class="card-body text-center">
                                <i [class]="item.Icon"></i>
                                <p class="card-text font-weight-semibold mb-0">
                                    {{item.Label}}
                                </p>
                                <p class="lead text-center">{{item.TotalCount}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </app-glide>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="card">
        <div class="card-body">
            <aw-wizard #awWizard [navBarLayout]="'large-filled-symbols'">
                <aw-wizard-step (stepExit)="ExitStep($event)" stepTitle="Select Document" style="min-height: 230px;">
                    <ng-template awWizardStepSymbol><i class="simple-icon-cloud-download"></i></ng-template>
                    <div>
                        <!-- <dropzone #dropZone [config]="config" [message]="'form-components.dropzone-message' | translate"
                            (error)="onUploadError($event)" (success)="onUploadSuccess($event)"></dropzone> -->
                        <ngx-dropzone (change)="onSelect($event)">
                            <ngx-dropzone-label>Drag and drop file(s) here or click.</ngx-dropzone-label>
                            <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
                                <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                            </ngx-dropzone-preview>
                        </ngx-dropzone>
                    </div>
                    <div class="text-right">
                        <!-- <button type="button" class="btn btn-primary" awPreviousStep disabled>{{ 'wizard.prev' |
                            translate
                            }}</button>{{" "}} -->
                        <button type="button" [disabled]="files.length === 0" class="btn btn-primary"
                            (click)="onFilePost()">{{
                            'wizard.next' | translate
                            }}</button>
                        <!-- awNextStep -->
                        <!-- [disabled]="files.length === 0" -->
                    </div>
                </aw-wizard-step>
                <aw-wizard-step stepTitle="Add Recipient" style="min-height: 230px;">
                    <ng-template awWizardStepSymbol><i class="simple-icon-user"></i></ng-template>
                    <div class="row">
                        <div class="col-lg-2 col-md-2" style="max-width: 15%;">
                            <div class="mb-1">
                                <button tooltip="Add New Recipient" placement="top" type="button"
                                    (click)="openModalAdd(addNewModal)" class="btn btn-primary mb-1 btn-sm">
                                    <div class="glyph-icon simple-icon-plus"> New Recipient</div>
                                </button>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-2" style="max-width: 16%;">
                            <div class="mb-1">
                                <button tooltip="Select Recipient" placement="top" type="button"
                                    (click)="openModal(selectRecipentModal)" class="btn btn-secondary mb-1">
                                    <div class="glyph-icon simple-icon-check"> Select Recipient</div>
                                </button>
                            </div>
                        </div>
                        <div class="col-lg-8 col-md-8">
                            <div class="custom-control custom-checkbox" style="margin-top: 3px;">
                                <input type="checkbox" (change)="this.isPriority = !this.isPriority"
                                    class="custom-control-input" id="customCheckThis" [(ngModel)]="customCheckThis">
                                <label class="custom-control-label" for="customCheckThis">Set multiple recipient
                                    order?</label>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <div class="custom-control custom-checkbox mb-4">
                                <input type="checkbox" (change)="this.isPriority = !this.isPriority"
                                    class="custom-control-input" id="customCheckThis" [(ngModel)]="customCheckThis">
                                <label class="custom-control-label" for="customCheckThis">Set multiple recipient
                                    order?</label>
                            </div>
                        </div>
                    </div> -->
                    <div class="row">
                        <div class="col-lg-12 col-md-12 mb-4">
                            <div class="card">
                                <div class="card-body">
                                    <h5 class="card-title">{{ tableName | translate}}</h5>
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Name</th>
                                                <th scope="col">Email</th>
                                                <th scope="col">Color</th>
                                                <th scope="col" *ngIf="isPriority">Priority</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let rec of recipentList; let indexOfelement = index;">
                                                <th scope="row">{{indexOfelement + 1}}</th>
                                                <td>{{rec.name}}</td>
                                                <td>{{rec.email}}</td>
                                                <td><div style=" height: 10px; width: 54px;" [style.background-color]="rec.sColorCode"><input type="color" name="" id="invisible" style="opacity:0.005 ;"[(ngModel)]="rec.sColorCode"></div></td> 
                                                <td *ngIf="isPriority">
                                                    <form [formGroup]="recipentsForm">
                                                        <ng-select class="w-100p" (change)="setPriority(rec)"
                                                            appearance="outline" formControlName="priority" #agreeSelect
                                                            labelForId="yesno" [searchable]="false">
                                                            <ng-option
                                                                *ngFor="let rec of recipentList; let index = index"
                                                                [value]="index + 1">{{index + 1}}</ng-option>
                                                        </ng-select>
                                                    </form>
                                                </td> 
                                                <td>
                                                    <i (click)="deleteRecipent(rec)"
                                                        class="glyph-icon simple-icon-trash"></i>
                                                        <!-- <div style="display:inline ; padding-left: 2px;"><i (click)="addNewEmail()"
                                                     class="glyph-icon simple-icon-pencil"></i></div> -->
                                                        
                                                         
                                                    
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="text-right">
                        <button type="button" class="btn btn-success" style="margin-right: 35px;" (click)="colorAssign()" >Assign Color</button>
                        <button type="button" class="btn btn-primary" awPreviousStep>Back</button>
                        <button type="button" class="btn btn-primary" [disabled]="recipentList.length === 0"
                            (click)="checkImages()" (click)="colorAssign()"  awNextStep>Next</button>
                        <!-- [disabled]="recipentList.length === 0" -->
                    </div>
                </aw-wizard-step>
                <aw-wizard-step stepTitle="Prepare Document" style="min-height: 230px;">
                    <ng-template awWizardStepSymbol><i class="simple-icon-docs"></i></ng-template>
                    <div class="row">
                        <div class="col-lg-1"></div>
                        <div class="col-lg-3 card p-20" style="    height: 600px;overflow: hidden;overflow-y: scroll;display: none;">
                            <div class="row" style="text-align: center;">
                                <div class="col-xl-12 col-lg-12 col-12 col-sm-12 mb-4" *ngFor="let p of images;">
                                    <div class="card-body" style="padding: 0;">
                                        <div class="position-relative">
                                            <a href="javascript:;">
                                                <img class="card-img-top image p-Top" [src]="p.base64"
                                                    style="width: 190px;" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="row">
                                <div class="col-xl-12 col-lg-12 col-12 col-sm-12 mb-4">
                                    <div class="card-body"
                                        style="padding: 0;height: 600px;overflow-y: scroll;overflow-x: hidden;width: 100%;padding-left: 6px;" >
                                                <div class="position-relative" style="overflow-y:hidden;overflow-x:scroll;"
                                            *ngFor="let p of images;index as i; trackBy:trackByFn">
                                            <!-- <a href="javascript:;"> -->
                                            <img attr.id="{{p.Id}}" #editorCanvas class="card-img-top image p-Top"
                                                (click)="myDrawingFunction(p, $event)" [src]="p.base64" [ngStyle]="setImageSize(p)" />
                                            <!-- </a> -->
                                            <!-- <div #editorCanvas class="canvas" attr.id="{{p.Id}}"
                                                (click)="myDrawingFunction(p, $event)"
                                                *ngFor="let p of images;index as i; trackBy:trackByFn"
                                                [ngStyle]="setImageSize(p)"
                                                style="display: inline-block;margin-top: 5px !important;position: relative;">
                                            </div> -->
                                        </div> 
                                </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-1"></div>
                        <!-- <div class="col-lg-2">
                            
                        </div> -->
                    </div>
                    <div class="text-left">
                        <button type="button" class="btn btn-primary" awPreviousStep
                            (click)="PanelState()">Back</button>
                        <button type="button" class="btn btn-primary" [disabled]="!isActive" awNextStep
                            (click)="PanelState()">Next</button>
                        <!-- [disabled]="!isActive" -->
                    </div>
                </aw-wizard-step>
                <aw-wizard-step stepTitle="Review Document" style="min-height: 230px;">
                    <ng-template awWizardStepSymbol><i class="simple-icon-eye"></i></ng-template>
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <div class="FirstContainer">
                                <form [formGroup]="finishForm">
                                    <h3 class="m-b">Review and Send</h3>
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <label class="form-group has-float-label">
                                                <input class="form-control" required formControlName="Subject"
                                                    name="Subject" (keyup)="focusOut()" />
                                                <span>Subject</span>
                                                <!-- <div *ngIf="!finishForm.subject.valid" class="invalid-tooltip">Subject is required!
                                            </div> -->
                                            </label>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <label class="form-group has-float-label">
                                                <textarea aria-describedby="basic-addon3" autocomplete="off"
                                                    name="Message" rows="5" class="form-control"
                                                    formControlName="Message" id="message"
                                                    (keyup)="focusOut()"></textarea>
                                                <span>Message</span>
                                                <!-- <div *ngIf="!finishForm.message.valid" class="invalid-tooltip">Message is required!
                                            </div> -->
                                            </label>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <div class="boxContainer">
                                <input class="inputTab" id="tab2" type="radio" name="tabs" checked>
                                <label class="labelTab" for="tab2"><i class="simple-icon-list"></i> Option</label>
                                <input class="inputTab" id="tab1" type="radio" name="tabs">
                                <label class="labelTab" for="tab1"><i class="simple-icon-layers"></i> Summary</label>


                                <section id="content1" class="summarySection">
                                    <div class="row">
                                        <div class="col-lg-12 divBottom">
                                            <strong>Document | <i (click)="onEdit(2)" style="cursor: pointer;"
                                                    class="simple-icon-note"></i></strong>
                                            <p *ngFor="let i of files; let indexOfelement = index;"
                                                style="margin-bottom: 0.3rem;"> {{indexOfelement + 1}}) {{i.name}}</p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-12 divBottom">
                                            <strong>Recipients | <i (click)="onEdit(1)" style="cursor: pointer;"
                                                    class="simple-icon-note"></i></strong>
                                            <p *ngFor="let i of recipentList" style="margin-bottom: 0.3rem;"> <i
                                                    class="simple-icon-user"></i> {{i.email}}</p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <p>Once the binder is completed, all
                                                recipients will receive a copy of the
                                                completed binder.</p>
                                        </div>
                                    </div>
                                </section>

                                <section id="content2">
                                    <div class="MuiTypography-root MuiTypography-body1" style="padding: 0px 18px;">
                                        <h4>Expiration </h4>
                                        <form [formGroup]="finishForm">

                                            <div class="row">
                                                <div class="input-group mb-3">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text" id="basic-addon3">Envelope
                                                            Expires
                                                            on:</span>
                                                    </div>
                                                    <input type="date" class="form-control" formControlName="expireDate"
                                                        (change)="dateChange($event.target.value)" id="expireDate" >
                                                </div>
                                                <div class="input-group mb-3">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text" id="basic-addon3">days from
                                                            now:</span>
                                                    </div>
                                                    <input type="number" class="form-control" formControlName="day"
                                                        (change)="setDays($event.target.value)" id="day"
                                                        aria-describedby="basic-addon3" autocomplete="off">
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </section>

                            </div>
                        </div>
                    </div>
                    <div class="text-right" style="margin-top: 10px;">
                        <button type="button" class="btn btn-primary" (click)="PanelState()"
                            awPreviousStep>Back</button>
                        <button type="button" class="btn btn-primary" [disabled]="!isFinish" (click)="Finish()"
                            awNextStep>Finish</button>
                    </div>
                </aw-wizard-step>
                <aw-wizard-step stepTitle="Finish" style="min-height: 230px;">
                    <ng-template awWizardStepSymbol><i class="simple-icon-share-alt"></i></ng-template>
                    <div class="text-center complete-message-centered">
                        <h2 class="mb-2">Thank You!</h2>
                        <p>Your document completed successfully!</p>
                        <button type="button" class="btn btn-primary" (click)="nevigateScreen()">Create
                            Document</button>
                    </div>
                </aw-wizard-step>
            </aw-wizard>
        </div>
    </div>
</div>

<ng-template #addNewModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Add New Recipient</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="recipentsForm">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon3">Name</span>
                </div>
                <input type="text" class="form-control" formControlName="name" id="name" aria-describedby="basic-addon3"
                     autocomplete="off">
                <div *ngIf="nameCheck" class="invalid-tooltip">Name is required!
                </div>
            </div>

            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon3">Email</span>
                </div>
                <input type="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" title="Invalid email address"
                    class="form-control" (keydown)="ValidateEmail($event.target.value)" formControlName="email"
                    id="email" aria-describedby="basic-addon3" autocomplete="off">
                <div *ngIf="emailCheck" class="invalid-tooltip">{{emailValidation}}
                </div>
            </div>
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <!-- <span class="input-group-text" id="basic-addon3">Color</span> -->
                </div>

                <!-- <input type="color" fornControlName="color"
                [style.background]="color"
                autocomplete="off"
                [(colorPicker)]="color"
                [cpPosition]="'bottom'"
                placeholder="Colour" /> -->

                
                <!-- <div [ngStyle]="{'background-color': selectiveColorCode[lastIndex]}" style="height: 38px; width:500px; margin-top: -38px; margin-left: 57px;"></div> -->


                <!-- <div [style.background-color]="show" style="height: 20px; width: 500px;" *ngFor="let show of selectiveColorCode let indexOfelement = index;">
                {{show}}
                </div> -->
                
            

                <!-- <input type="color"  class="form-control"  [(colorPicker)]="color"
                 formControlName="sColorCode" id="sColorCode" aria-describedby="basic-addon3"
                     autocomplete="off">


                <div *ngIf="sColorCodeCheck" class="invalid-tooltip">Color Code is required!
                </div>  -->


            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="submit" (click)="addNewEmail()" class="btn btn-primary">Save</button>
        <!-- <button type="button" class="btn btn-outline-primary">Close</button> -->
    </div>
</ng-template>
<ng-template #selectRecipentModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Select Existing Recipient</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-lg-12">
                <ngx-datatable #dataTable class="bootstrap" [rows]="row" [columns]="columns" [columnMode]="'force'"
                    style="height: 350px;" [headerHeight]="20" [footerHeight]="80" [limit]="5" [selected]="selectedRows"
                    [scrollbarV]="true" [rowHeight]="25" [selectionType]="'checkbox'" [selectAllRowsOnPage]="false"
                    (select)='onSelection($event)'>
                </ngx-datatable>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" (click)="SaveSections()" class="btn btn-primary">Save</button>
        </div>
    </div>
</ng-template>

<div *ngIf="isOpenSwitcher" class="{{panelToggleClass}}">
    <div class="row">
        <div class="col-lg-12">
            <h3 style="margin-top: 5px;border-bottom: 1px solid #ddd;font-weight:bold;"> <i (click)="panelClose()"
                    style="cursor: pointer;font-weight: bold;font-size: 15px;" class="simple-icon-arrow-right"></i>
                {{gadgetName}}</h3>
        </div>     
    </div>
    <div class="row">
        <div class="col-lg-12">
            <form [formGroup]="panelForm">
                <label class="">Assign To:</label>
                <select  #mee class="form-control" formControlName="name" (change)="assignTitleColor($event.target.value)">
                    <option *ngFor="let rec of recipentList" [value]="rec.name">{{rec.name}}
                    </option>
                </select>


                <!-- <label class="">Color Code To:</label>
                <select class="form-control" formControlName="sColorCode" >
                    <option *ngFor="let cor of recipentList" [value]="cor.sColorCode">{{cor.sColorCode}} 
                    </option>
                </select> -->



                <!-- <ng-select appearance="outline" formControlName="name" #panelSelect labelForId="yesno"
                    (change)="setAssignTo($event)" [searchable]="false">
                    <ng-option *ngFor="let rec of recipentList; let index = index" [value]="rec.name">{{rec.name}}
                    </ng-option>
                </ng-select> -->



            </form>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <div class="custom-control custom-checkbox mb-3">
                <input type="checkbox" id="placementCheckBox" class="custom-control-input">
                <label for="placementCheckBox" class="custom-control-label">Place on every page.</label>
            </div>
        </div>
    </div>

    <button id="myButton" class="btn btn-primary btn-block" style="width: max-content;float: right;margin-right: 10px;"
        (click)="placementSaving()">Save</button>


</div>

<app-application-menu [isOpen]="isRightPanel" *ngIf="isRightPanel">
    <perfect-scrollbar class="scroll DocScroll" [config]="{suppressScrollX: true}">
        <ul class="list-unstyled">
            <li (click)="activeControls('Signature')">
                <a rel="noopener noreferrer">
                    <i class="iconsminds-pen-2"></i>
                    Signature
                </a>
            </li>
            <li (click)="activeControls('Initials')">
                <a rel="noopener noreferrer">
                    <i class="iconsminds-voice"></i>
                    Initials</a>
            </li>
            <li (click)="activeControls('Stamp')">
                <a rel="noopener noreferrer">
                    <i class="iconsminds-stamp"></i>
                    Stamp</a>
            </li>
            <!-- <li (click)="activeControls('Stamp')">
                <a rel="noopener noreferrer">
                    <i class="iconsminds-stamp"></i>
                    Stamp</a>
            </li> -->
            <li (click)="activeControls('DateSign')">
                <a rel="noopener noreferrer">
                    <i class="simple-icon-calendar"></i>
                    Date Signed</a>
            </li>
            <li (click)="activeControls('Name')">
                <a rel="noopener noreferrer">
                    <i class="iconsminds-profile"></i>
                    Name</a>
            </li>
            <li (click)="activeControls('FirstName')">
                <a rel="noopener noreferrer">
                    <i class="iconsminds-indent-first-line"></i>
                    First Name</a>
            </li>
            <li (click)="activeControls('LastName')">
                <a rel="noopener noreferrer">
                    <i class="iconsminds-add-space-after-paragraph"></i>
                    Last Name</a>
            </li>
            <li (click)="activeControls('Email')">
                <a rel="noopener noreferrer">
                    <i class="iconsminds-mail-with-at-sign"></i>
                    Email</a>
            </li>
            <li (click)="activeControls('Company')">
                <a rel="noopener noreferrer">
                    <i class="iconsminds-the-white-house"></i>
                    Company</a>
            </li>
            <li (click)="activeControls('Title')">
                <a rel="noopener noreferrer">
                    <i class="iconsminds-indent-right-margin"></i>
                    Title</a>
            </li>
            <li (click)="activeControls('Text')">
                <a rel="noopener noreferrer">
                    <i class="iconsminds-add-space-after-paragraph"></i>
                    Text</a>
            </li>
            <li (click)="activeControls('CheckBox')">
                <a rel="noopener noreferrer">
                    <i class="simple-icon-check"></i>
                    Check Box</a>
            </li>
            <li (click)="activeControls('Striker')">
                <a rel="noopener noreferrer">
                    <i class="simple-icon-crop"></i>
                    Striker
                </a>
            </li>
        </ul>
    </perfect-scrollbar>
</app-application-menu>

<ng-template #canvasSignatureUser>
    <div class="modal-header">
        <h4 class="modal-title pull-left">ADOPT YOUR Signature</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRefSign.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-12 MuiGrid-grid-md-12"
            style="margin-bottom: 10px;"><strong>DRAW YOUR Signature</strong>
            <div style="border: 2px solid rgb(196, 196, 196); border-radius: 5px; width: 300px; height: 150px;">
                <canvas id='signaturePadUser'></canvas>
            </div>
        </div>

        <div class="text-right">
            <input type="file" id="imgupload" (change)="getFileDetails($event)" style="display:none" />
            <button type="button" class="btn btn-primary" (click)="onFIleUpload($event)">Upload</button>{{" "}}
            <button type="button" class="btn btn-primary" (click)="Undo($event)">Clear</button>{{" "}}
            <button type="button" class="btn btn-primary" (click)="onSubmit()">Submit</button>
            <!-- [disabled]="files.length === 0" -->
        </div>
    </div>
</ng-template>
<ng-template #modalPlacement>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Options</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRefPlacement.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-lg-12">
                <div class="custom-control custom-checkbox mb-3">
                    <input type="checkbox" id="placementCheckBox" class="custom-control-input">
                    <label for="placementCheckBox" class="custom-control-label">Place on every page.</label>
                </div>
            </div>
        </div>
        <div class="text-right">
            <button type="button" class="btn btn-primary" (click)="SaveInternal()">Save</button>
            <!-- [disabled]="files.length === 0" -->
        </div>
    </div>
</ng-template >





<simple-notifications></simple-notifications>
<style>
    .viewer-thumbnail {
        background: #fff !important;
    }

    .leftEmbed img {
        width: 180px !important;
        height: 180px !important;
    }

    .example-box {
        width: 200px;
        height: 200px;
        padding: 10px;
        box-sizing: border-box;
        border: solid 1px #ccc;
        color: rgba(0, 0, 0, 0.87);
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        background: #fff;
        border-radius: 4px;
        position: relative;
        z-index: 1;
        transition: box-shadow 200ms cubic-bezier(0, 0, 0.2, 1);
        box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
            0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    }

    .example-box.free-dragging {
        box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
            0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
    }

    .example-handle {
        position: absolute;
        top: 10px;
        right: 10px;
        color: #ccc;
        cursor: move;
        width: 24px;
        height: 24px;
    }

    .canvas,
    .image {
        /* padding-top: 15px; */
        box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
        border-radius: 10px;
        margin-bottom: 3px;
    }

    .onSelected tr:hover,
    .onSelected tr.selected {
        background-color: #ccc
    }

    .FirstContainer,
    .boxContainer {
        border-radius: 10px;
        box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
        padding: 15px;
        height: 250px;
    }

    .m-b {
        margin-bottom: 15px;
    }

    /* Tab CSS */
    .boxContainer main {
        min-width: 320px;
        max-width: 1100px;
        padding: 20px;
        margin: 0 auto;
        background: #fff;
    }

    .boxContainer section {
        display: none;
        padding: 20px 0 0;
        border-top: 1px solid #ddd;
    }

    .boxContainer .inputTab {
        display: none;
    }

    .boxContainer .labelTab {
        display: inline-block;
        margin: 0 0 -1px;
        padding: 8px 25px;
        font-weight: 600;
        text-align: center;
        color: #bbb;
        border: 1px solid transparent;
    }

    .boxContainer labelTab:before {
        font-family: fontawesome;
        font-weight: normal;
        margin-right: 10px;
    }

    .boxContainer labelTab:hover {
        color: #888;
        cursor: pointer;
    }

    .boxContainer .inputTab:checked+label {
        color: #555;
        border: 1px solid #ddd;
        border-top: 2px solid #6c757d;
        border-bottom: 1px solid #fff;
    }

    #tab1:checked~#content1,
    #tab2:checked~#content2 {
        display: block;
    }

    .summarySection {
        height: 185px;
        overflow: hidden;
        overflow-y: scroll;
        padding-top: 10px !important;
    }

    .divBottom {
        border-bottom: 1px solid #ddd;
        margin-bottom: 4px;
    }

    /* Panel */
    .Panel {
        width: 280px !important;
        position: fixed;
        z-index: 1030;
        top: 50%;
        right: 0;
        transform: translate(280px, -50%);
        padding: 10px;
        padding-bottom: 10px;
        background: #fff;
        height: 100%;
        box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
            0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
    }

    .shown {
        transform: translate(0, calc(-50% + 0.5px));
        transition: transform 0.4s ease-out;
    }

    .hidden {
        transform: translate(280px, -50%);
        transition: transform 0.4s ease-out;
    }

    /* .recipientTable tbody,
    .recipientTable thead {
        display: block;
    }

    .recipientTable tbody {
        height: 50px;
        overflow-y: auto;
        overflow-x: hidden;
    } */

    ul {
        margin-bottom: initial;
    }

    ul li {
        position: relative;
        margin-bottom: 5px;
    }

    ul li i {
        font-size: 2em;
    }

    ul li a {
        height: 80px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        border-bottom: 1px solid #f3f3f3;
        color: #3a3a3a;
        transition: color 300ms;
        transition: background 300ms;
        cursor: pointer;
    }
</style>