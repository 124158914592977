// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { UserRole } from '../app/shared/auth.roles';

export const environment = {
  production: false,
  buyUrl: 'https://1.envato.market/6NV1b',
  loginUrl: "user/login",
  RegisterUrl: "user/register",
  SCARF_ANALYTICS: false,
  adminRoot: '/app',
  userRoot: '/mailBox',
  //Local URL
 
  //apUrl: 'http://localhost:5000/', 
  //afterEmailVerification: 'http://localhost:5000/user/login', 
  //apiUrl: 'http://localhost:16062/api/', 
  //*
  //Live URL
  //appUrl: 'http://localhost:16062/', 
  //afterEmailVerification: 'http://localhost:16062/user/login', 
  //apiUrl: 'http://localhost:16062/api/',

  // appUrl: 'https://eSignature.bizsoft.app/', 
   //appUrl: 'https://eboss.bizsoft.app/', 
   afterEmailVerification: 'https://eboss.bizsoft.app/user/login', 
   apiUrl: 'https://eSignature.bizsoft.app/api/',
  //*
  defaultMenuType: 'menu-default',
  subHiddenBreakpoint: 1440,
  menuHiddenBreakpoint: 768,
  themeColorStorageKey: 'vien-themecolor',
  isMultiColorActive: true,
  defaultColor: 'light.blueyale',
  isDarkSwitchActive: true,
  defaultDirection: 'ltr',
  themeRadiusStorageKey: 'vien-themeradius',
  isAuthGuardActive: true,
  defaultRole: UserRole.Admin,
  firebase: {
    apiKey: 'AIzaSyCqoNLB_jTw4nncO12qR-eDH9gAeWiZVaw',
    authDomain: 'vien-angular-login.firebaseapp.com',
    databaseURL: 'https://vien-angular-login.firebaseio.com',
    projectId: 'vien-angular-login',
    storageBucket: 'vien-angular-login.appspot.com',
    messagingSenderId: '16217062888',
    appId: '1:16217062888:web:6b08232ca0c9662fedb85d',
    measurementId: 'G-8ETT79WRRN'
  }
};
