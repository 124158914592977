// Angular Imports
import { NgModule } from '@angular/core';

// This Module's Components
import { PakagePageComponent } from './pakage-page.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [
        FormsModule, ReactiveFormsModule, CommonModule
    ],
    declarations: [
        PakagePageComponent,
    ],
    exports: [
        PakagePageComponent,
    ]
})
export class PakagePageModule {

}
