import { Component, OnInit, OnDestroy, HostListener, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { SidebarService, ISidebar } from '../sidebar/sidebar.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LangService, Language } from 'src/app/shared/lang.service';
import { AuthService } from 'src/app/shared/auth.service';
import { environment } from 'src/environments/environment';
import { getThemeColor, setThemeColor } from 'src/app/utils/util';
import { ApplicationMenuService } from '../application-menu/application-menu.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { FormControl, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { pairwise, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html',
})
export class TopnavComponent implements OnInit, OnDestroy {
  _rows: any[] = [];
  buyUrl = environment.buyUrl;
  adminRoot = 'http://localhost:5000/#';
  sidebar: ISidebar;
  subscription: Subscription;
  displayName = 'Sarah Cortney';
  languages: Language[];
  currentLanguage: string;
  isSingleLang;
  isFullScreen = false;
  isDarkModeActive = false;
  searchKey = '';
  modalRef: any;
  modalRefMessage: any;
  msg: string;
  showIcon: boolean = true;
  marginLeft: any;

  cx: any;
  canvasEl: any;
  modalRefPlacement: any;
  dataURL: any;
  @ViewChild('signaturePadChange') canvas: ElementRef
  isDraw: boolean = false;


  constructor(
    private sidebarService: SidebarService,
    private authService: AuthService,
    private router: Router,
    private langService: LangService,
    private appService: ApplicationMenuService,
    private modalService: BsModalService,
    private http: HttpClient,
    private activeroute: ActivatedRoute
  ) {
    this.languages = this.langService.supportedLanguages;
    // this.currentLanguage = this.langService.languageShorthand;
    this.isSingleLang = this.langService.isSingleLang;
    this.isDarkModeActive = getThemeColor().indexOf('dark') > -1 ? true : false;

    this.activeroute.queryParams.subscribe(async (params) => {

      if (params.docId) {
        this.showIcon = false;
        this.marginLeft = '10px'
      } else {
        this.showIcon = true;
        this.marginLeft = '0px'
      }
    });
  }

  onDarkModeChange(event): void {
    let color = getThemeColor();
    if (color.indexOf('dark') > -1) {
      color = color.replace('dark', 'light');
    } else if (color.indexOf('light') > -1) {
      color = color.replace('light', 'dark');
    }
    setThemeColor(color);
    setTimeout(() => {
      window.location.reload();
    }, 200);
  }

  fullScreenClick(): void {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      document.documentElement.requestFullscreen();
    }
  }

  @HostListener('document:fullscreenchange', ['$event'])
  handleFullscreen(event): void {
    if (document.fullscreenElement) {
      this.isFullScreen = true;
    } else {
      this.isFullScreen = false;
    }
  }

  onLanguageChange(lang): void {
    if (lang) {
      this.currentLanguage = lang.PackageName;
      localStorage.setItem('purchaseID', lang.purchaseID)
    }
    // this.langService.language = lang.code;
  }

  async ngOnInit(): Promise<void> {
    if (await this.authService.getUser()) {
      this.displayName = await this.authService.getUser().then((user) => {
        return user.displayName;
      });
    }
    this.subscription = this.sidebarService.getSidebar().subscribe(
      (res) => {
        this.sidebar = res;
      },
      (err) => {
        console.error(`An error occurred: ${err.message}`);
      }
    );
    await this.getUserPackages();
    if (this._rows.length > 0) {
      this.currentLanguage = this._rows[0].PackageName;
      localStorage.setItem('purchaseID', this._rows[0].purchaseID)
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  menuButtonClick = (
    e: { stopPropagation: () => void },
    menuClickCount: number,
    containerClassnames: string
  ) => {
    if (e) {
      e.stopPropagation();
    }

    setTimeout(() => {
      const event = document.createEvent('HTMLEvents');
      event.initEvent('resize', false, false);
      window.dispatchEvent(event);
    }, 350);

    this.sidebarService.setContainerClassnames(
      ++menuClickCount,
      containerClassnames,
      this.sidebar.selectedMenuHasSubItems
    );
  }

  menuRightClick = (
    e: { stopPropagation: () => void },
  ) => {
    if (e) {
      e.stopPropagation();
    }
    this.appService.isOpen = !this.appService.isOpen;
    var panel: any = document.getElementsByClassName('app-menu')[0];
    var container: any = document.getElementsByClassName('mainContainer')[0];
    if (this.appService.isOpen) {
      if (panel) {
        panel.style.display = "block";
        if (container) {
          container.style.marginRight = '145px';
        } else {
          container.style.marginRight = '50px';
        }
      }
      else {
        panel.style.display = "none"
      }
    } else {
      panel.style.display = "none"
      var container: any = document.getElementsByClassName('mainContainer')[0];
      if (container) {
        container.style.marginRight = '50px';
      }
    }
  }

  mobileMenuButtonClick = (
    event: { stopPropagation: () => void },
    containerClassnames: string
  ) => {
    if (event) {
      event.stopPropagation();
    }
    this.sidebarService.clickOnMobileMenu(containerClassnames);
  }

  onSignOut(): void {
    this.authService.signOut().subscribe(() => {
      localStorage.clear();
      this.router.navigate(['/user/login']);
    });
  }

  searchKeyUp(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.search();
    } else if (event.key === 'Escape') {
      const input = document.querySelector('.mobile-view');
      if (input && input.classList) {
        input.classList.remove('mobile-view');
      }
      this.searchKey = '';
    }
  }

  searchAreaClick(event): void {
    event.stopPropagation();
  }
  searchClick(event): void {
    if (window.innerWidth < environment.menuHiddenBreakpoint) {
      let elem = event.target;
      if (!event.target.classList.contains('search')) {
        if (event.target.parentElement.classList.contains('search')) {
          elem = event.target.parentElement;
        } else if (
          event.target.parentElement.parentElement.classList.contains('search')
        ) {
          elem = event.target.parentElement.parentElement;
        }
      }

      if (elem.classList.contains('mobile-view')) {
        this.search();
        elem.classList.remove('mobile-view');
      } else {
        elem.classList.add('mobile-view');
      }
    } else {
      this.search();
    }
    event.stopPropagation();
  }

  search(): void {
    if (this.searchKey && this.searchKey.length > 1) {
      this.router.navigate([this.adminRoot + '/#'], {
        queryParams: { key: this.searchKey.toLowerCase().trim() },
      });
      this.searchKey = '';
    }
  }

  @HostListener('document:click', ['$event'])
  handleDocumentClick(event): void {
    const input = document.querySelector('.mobile-view');
    if (input && input.classList) {
      input.classList.remove('mobile-view');
    }
    this.searchKey = '';
  }


  openModalAdd(template: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(template);
    setTimeout(() => {

      this.canvasEl = document.getElementById('signaturePadChange');

      this.isDraw = false;
      this.cx = this.canvasEl.getContext('2d');

      this.cx.lineWidth = 5;
      this.cx.lineCap = 'round';
      this.cx.strokeStyle = '#000';
      this.captureEvents(this.canvasEl);

    }, 0);
  }

  @ViewChild('modalmessage') modalmessage: TemplateRef<any>

  openModalMessage(template: TemplateRef<any>): void {
    this.modalRefMessage = this.modalService.show(template);
  }

  OK() {
    this.modalRefMessage.hide();
  }

  caption: any;

  async Submit() {

    let password: any = document.getElementById('passwordField');
    let repassword: any = document.getElementById('repasswordField');

    if (password.value === "" && repassword.value === "") {

      this.caption = 'Warning';
      this.msg = 'Password and Re-Type Password is required!'
      this.openModalMessage(this.modalmessage);
    }
    else if (password.value === "") {

      this.caption = 'Warning';
      this.msg = 'Password is required!'
      this.openModalMessage(this.modalmessage);
    } else if (repassword.value === "") {

      this.caption = 'Warning';
      this.msg = 'Re-Type Password is required!'
      this.openModalMessage(this.modalmessage);
    }
    else if (password.value !== repassword.value) {

      this.caption = 'Validator';
      this.msg = 'Password does not match!'
      this.openModalMessage(this.modalmessage);
    }
    else {

      let id = Number(localStorage.getItem('userID'));
      if (this.canvasEl) {
        this.dataURL = this.canvasEl.toDataURL("image/png");
      }
      let baseUrl = environment.apiUrl + 'Registration/passwordChange';
      let sign = this.dataURL && this.isDraw ? this.dataURL : '';

      let body = {
        id: id,
        password: password.value,
        signatureNote: sign
      }
      await this.http.post(baseUrl, body).toPromise().then((user: any) => {

        if (user === "1") {

          this.caption = 'Success';
          this.msg = "Password change successfully."
          this.openModalMessage(this.modalmessage);
          this.modalRef.hide();
        } else {

          // this.modalRef.hide();
        }
      }).catch((error) => {

        this.caption = 'Error';
        this.msg = error.message
        this.openModalMessage(this.modalmessage);
      });
    }
  }

  show: boolean = false;
  showre: boolean = false;

  textPassword() {
    this.show = !this.show;
  }
  textRePassword() {
    this.showre = !this.showre;
  }

  onFIleUpload(ev) {
    if (ev) {
      var upload = document.getElementById('imgupload').click();
    }
  }
  getFileDetails(e: any) {

    var img = new Image();

    if (this.cx) {
      img.onload = async () => {

        this.cx.drawImage(img, 0, 0); // Or at whatever offset you like

        img.width = img.width / 5;

        img.height = img.height / 5;

      };

      img.src = URL.createObjectURL(e.target.files[0]);

    }
  }
  Undo(ev) {
    if (ev && this.cx) {
      this.isDraw = false;
      this.cx.clearRect(0, 0, 450, 300);
    }
  }
  drawOnCanvas(prevPos: { x: number, y: number }, currentPos: { x: number, y: number }) {
    if (!this.cx) {
      this.isDraw = false;
      return;
    }
    this.cx.beginPath();
    if (prevPos) {
      this.cx.moveTo(prevPos.x, prevPos.y); // from
      this.cx.lineTo(currentPos.x, currentPos.y);
      this.cx.stroke();
      this.isDraw = true;
    }

  }
  captureEvents(canvasEl: HTMLCanvasElement) {
    // this will capture all mousedown events from the canvas element
    fromEvent(canvasEl, 'mousedown')
      .pipe(
        switchMap((e) => {
          // after a mouse down, we'll record all mouse moves
          return fromEvent(canvasEl, 'mousemove')
            .pipe(
              // we'll stop (and unsubscribe) once the user releases the mouse
              // this will trigger a 'mouseup' event    
              takeUntil(fromEvent(canvasEl, 'mouseup')),
              // we'll also stop (and unsubscribe) once the mouse leaves the canvas (mouseleave event)
              takeUntil(fromEvent(canvasEl, 'mouseleave')),
              // pairwise lets us get the previous value to draw a line from
              // the previous point to the current point    
              pairwise()
            )
        })
      )
      .subscribe((res: [MouseEvent, MouseEvent]) => {
        const rect = canvasEl.getBoundingClientRect();
        // previous and current position with the offset
        const prevPos = {
          x: res[0].clientX - rect.left,
          y: res[0].clientY - rect.top
        };
        const currentPos = {
          x: res[1].clientX - rect.left,
          y: res[1].clientY - rect.top
        };
        // this method we'll implement soon to do the actual drawing
        this.drawOnCanvas(prevPos, currentPos);
      });
  }
  async getUserPackages() {
    this._rows = [];
    let userid = localStorage.getItem('userID')
    await this.http.get(environment.apiUrl + 'Packages/getActiveUserPackages?userid=' + userid).toPromise().then((user1: any) => {
      if (user1 && user1.length && user1.length > 0) {
        this._rows = user1;
      } else {
        let obj = {
          PackageID: 1,
          PackageName: 'Free',
          purchaseID: 0
        }
        this._rows.push(obj)
      }
    }).catch((error) => {

      if (error) {
      }
    });
  }

}
