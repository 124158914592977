import { environment } from 'src/environments/environment';
import { UserRole } from '../shared/auth.roles';
const adminRoot = environment.adminRoot;

export interface IMenuItem {
  id?: string;
  icon?: string;
  label: string;
  to: string;
  newWindow?: boolean;
  subs?: IMenuItem[];
  roles?: UserRole[];
}

const data: IMenuItem[] = [
  {
    icon: 'simple-icon-speedometer',
    label: 'Dashboard',
    to: `${adminRoot}/cDoc`,
    roles: [UserRole.Admin, UserRole.Editor],
  },
  {
    icon: 'simple-icon-paper-plane',
    label: 'Mail Box',
    to: `${adminRoot}/mailBox`,
    roles: [UserRole.Admin, UserRole.Editor],
    subs: [
      {
        icon: 'iconsminds-mail-inbox',
        label: 'Inbox',
        to: `${adminRoot}/mailBox/inbox`,
      },
      {
        icon: 'simple-icon-paper-plane',
        label: 'Sent Mail',
        to: `${adminRoot}/mailBox/sentMail`,
      },
      // {
      //   icon: 'iconsminds-folder-edit',
      //   label: 'Drafts',
      //   to: `${adminRoot}/mailBox/drafts`,
      // },
      // {
      //   icon: 'iconsminds-video-len',
      //   label: 'Deleted',
      //   to: `${adminRoot}/mailBox/deleted`,
      // },
    ],

  },
  {
    icon: 'iconsminds-folder-add--',
    label: 'Create Doc',
    to: `${adminRoot}/cDoc`,
    roles: [UserRole.Admin, UserRole.Editor],
  },
  {
    icon: 'simple-icon-user-follow',
    label: 'My Account',
    to: `${adminRoot}/myAccount`,
    roles: [UserRole.Admin, UserRole.Editor],
  },
  // {
  //   icon: 'iconsminds-three-arrow-fork',
  //   label: 'menu.second-menu',
  //   to: `${adminRoot}/second-menu`,
  //    // roles: [UserRole.Editor],
  //   subs: [
  //     {
  //       icon: 'simple-icon-paper-plane',
  //       label: 'menu.second',
  //       to: `${adminRoot}/second-menu/second`,
  //     },
  //   ],
  // },
  // {
  //   icon: 'iconsminds-bucket',
  //   label: 'menu.blank-page',
  //   to: `${adminRoot}/blank-page`,
  // },
  // {
  //   icon: 'iconsminds-library',
  //   label: 'menu.docs',
  //   to: 'https://vien-docs.coloredstrategies.com/',
  //   newWindow: true,
  // },
];
export default data;
