import { CdkDragEnd } from '@angular/cdk/drag-drop';
import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output,TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
    selector: 'striker',
    templateUrl: 'striker.component.html',
    styleUrls: ['striker.component.scss']
})
export class StrikerComponent implements OnInit, AfterViewInit {
    componentName = 'Striker';
    modalRef: BsModalRef;
    widthSize:any;

    @ViewChild('size') div: ElementRef

    constructor(private modalService: BsModalService){

    }

    @Input()
    type: number = 1;

    @Output()
    onClick: EventEmitter<any> = new EventEmitter<any>();

    @Input()
    isDisabled: boolean = true;

    click() {
        if (this.isDisabled)
            this.onClick.emit(this);
    }

    @Input()
    title: any;
    
    @Input()
    titleColor: any;

    @Input()
    left: any;

    @Input()
    top: any;

    @Input()
    id: any;

    @Input()
    parentId: any;

    @Input()
    imageSource: any;

    @Input()
    DocPageId: any;

    ImageX: any;
    ImageY: any;

    async ngOnInit() {
        
        this.yAxis = Number(this.top.split('px')[0]);

        // if (this.top)
        
        if (this.type === 2 || this.type === 3) {
            this.top = this.yAxis + 20 + 'px'
        }
    }

    AddStyle(ev){
     
        return{
            'background-color':this.titleColor
        }
    }

    @Output()
    onAdd: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    onRemove: EventEmitter<any> = new EventEmitter<any>();
    @Output()
    onCopy: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    onRightClick: EventEmitter<any> = new EventEmitter<any>();
    positionTop: any;

    Copy() {
        this.status = false;

        this.onCopy.emit(this);
    }

    xAxis: number;
    yAxis: number;
    ngAfterViewInit() {
        
        if (this.top && this.left) {
            
            this.xAxis = Number(this.left.split('px')[0]);
            this.yAxis = Number(this.top.split('px')[0]);

        }

    }


    status: boolean;
    onright(ev) {

        if (ev) {
            if (this.top) {

                let p = Number(this.top.split('px')[0]);

                this.positionTop = (p + 70) + 'px'

            }
            let v = ev;
            ev.preventDefault();
            this.status = true;

            this.onRightClick.emit(v);
        }
    }

    Add(ev) {
        
        let obj = {
            id: this.id,
            parentId: this.parentId,
            ev: ev,
            event: this
        }
        this.status = true;
        this.onAdd.emit(obj)
    }

    Remove(ev) {

        let obj = {
            id: this.id,
            parentId: this.parentId
        }
        this.onRemove.emit(obj)
    }

    @HostListener("document:click", ["$event"]) clickedOutside(event: any) {
        this.status = false;
    }

    @Input()
    subId: any;

    @Output()
    changePosition: EventEmitter<any> = new EventEmitter<any>();
    dropped($event: CdkDragEnd) {
        if ($event) {
            const { offsetLeft, offsetTop } = $event.source.element.nativeElement;
            const { x, y } = $event.distance;
            this.xAxis = this.xAxis + x;
            this.yAxis = this.yAxis + y;
            this.changePosition.emit(this)
        }
    }

// @Input() shapesToDraw=[];
// shapeType = 'line';
// setType(type: string) { this.shapeType = type; }
// @Input() currentShape: Subject<Shape>;
// createdShape: Shape;

// startDrawing(evt: MouseEvent) {
//   this.createdShape = {
//     type: this.shapeType,
//     x: evt.offsetX,
//     y: evt.offsetY,
//     w: 0,
//     h: 0
//   };
//   this.shapesToDraw.push(this.createdShape);
// }

// keepDrawing(evt: MouseEvent) {
//   if (this.createdShape) {
//     // this.currentShape.next(this.createdShape);
//     this.createdShape.w = evt.offsetX - this.createdShape.x;
//     this.createdShape.h = evt.offsetY - this.createdShape.y;
//   }
// }

// stopDrawing(evt) {
//   this.createdShape = null;
// }

openStriker(template: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(template);
  }

//   openModal(template: TemplateRef<any>): void {
//     debugger
//       this.modalRef = this.modalService.show(template);
// }

widthSelection(width?){
    debugger
    this.widthSize = width;
    // console.log("this is it",this.widthSize);
    
}
}



