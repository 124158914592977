import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';

@Component({
    selector: 'pakage-page',
    templateUrl: 'pakage-page.component.html',
    styleUrls: ['pakage-page.component.scss']
})
export class PakagePageComponent {
    price: any;

    constructor(private http: HttpClient) {
        
        this.getJsonData().subscribe((i) => {
            
            if (i) {
                
                this.price = i.Price;
                
            }
        });


    }

    public getJsonData(): Observable<any> {
        
        const a = this.http
            .get("../../../../assets/Json/pakage.json").map((res: any) => {
                return res;
            });
        
        return a;
    }


}
