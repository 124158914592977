<!--component html goes here -->
<div (contextmenu)="onright($event)" id={{id}} cdkDrag [ngStyle]="{'left': left,'top': top}" *ngIf="type === 1" data-toggle="tooltip" data-placement="top" title={{title}} titleColor="{{titleColor}}"
  (click)="click()" class="Company-997cbd3f-c3ce-411c-bf33-f7d1442569d5" (cdkDragEnded)="dropped($event)"
    style="position: absolute; z-index: 9; top: 480px; left: 133.5px;">
    <div style="display: flex;">
        <div (click)="Add($event)" style="width: -webkit-fill-available; text-align: right; margin-right: 3px;"> <svg
                class="MuiSvgIcon-root MuiSvgIcon-colorPrimary" focusable="false" viewBox="0 0 24 24" aria-hidden="true"
                style="background: transparent;width: 15px; height: 15px; border: 1px solid; border-radius: 10px;">
                <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"></path>
            </svg>
            <!-- <div #menu style="top: 20px;left: 35px;font-size: 15px;" class="overlay-text" [ngClass]="status ? 'open' : ''">
            <a class="dropdown-item px-2 py-1" id="1" type="button" (click)="Copy()">
                Place on every page</a>

        </div> -->
        </div>
        <div (click)="Remove($event)"> <svg class="MuiSvgIcon-root MuiSvgIcon-colorPrimary" focusable="false"
                viewBox="0 0 24 24" aria-hidden="true"
                style="background: transparent;width: 15px; height: 15px; border: 1px solid; border-radius: 10px;">
                <path
                    d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z">
                </path>
            </svg> </div>
    </div>
    <div  [ngStyle]="AddStyle()"  class=""
        style="background: transparent;border: 0px solid green; padding: 2px; z-index: 10; width: max-content; cursor: move; color: black; border-radius: 10px; box-shadow: rgba(80, 80, 80, 0.2) 0px 1px 8px 0px, rgba(80, 80, 80, 0.14) 0px 3px 4px 0px, rgba(80, 80, 80, 0.12) 0px 3px 3px -2px;"
        >
        <div style="width: 60px; height: 14px; text-align: center;"><i class="" style="font-size: 1em;"></i>
            <b>Company</b>
        </div>
    </div>
</div>

<div *ngIf="type === 2" [ngStyle]="{'left': left,'top': top}" id={{id}}
    class="Company-71f5c4c4-0056-4fe8-9265-261fcead480d"
    style="position: absolute; z-index: 9;cursor: pointer !important;">
    <input type="text" id="text" name="text" placeholder="Enter Company" (change)="click()" [(ngModel)]="imageSource">
</div>

<div *ngIf="type === 3" [ngStyle]="{'left': left,'top': top}" id={{id}}
    class="Company-71f5c4c4-0056-4fe8-9265-261fcead480d"
    style="position: absolute; z-index: 9;cursor: pointer !important;">
    <input class="customInput" type="text" id="text" name="text" placeholder="Enter Company" [(ngModel)]="imageSource" disabled>
</div>


<style>
    .overlay-text {
        display: none;
        position: absolute;
        width: 200px;
        padding: 10px;
        background: transparent;
        border-radius: 5px;
        z-index: 10;
        box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
    }

    .overlay-text.open {
        display: block;
    }
</style>