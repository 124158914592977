import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ApplicationMenuService } from './application-menu.service';

@Component({
  selector: 'app-application-menu',
  templateUrl: './application-menu.component.html'
})

export class ApplicationMenuComponent implements OnInit {
  
  @Input()
  isOpen: boolean = false;

  constructor(private applicationService: ApplicationMenuService) {
  }

  async ngOnInit(){
    
    this.isOpen = this.applicationService.isOpen;
  }

  @HostListener('document:click', ['$event'])
  handleDocumentClick(event): void {
    if (this.isOpen) {
      this.toggle();
    }
  }

  toggle(): void {
    this.isOpen = !this.isOpen;
  }

  menuClick(event: MouseEvent): void {
    event.stopPropagation();
  }
}
