import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef, ViewChild, ElementRef, ViewContainerRef, ComponentFactoryResolver, ViewChildren, QueryList, HostListener } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { WizardComponent } from 'angular-archwizard';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ContextMenuComponent } from 'ngx-contextmenu';
import { DropzoneComponent } from 'ngx-dropzone-wrapper';
import { environment } from '../../../../environments/environment.prod';
import { Overlay } from '@angular/cdk/overlay';
import { DocumentEditorComponent } from '../document-editor/document-editor.component';
import { InitialsComponent } from '../Controls/initials/initials.component';
import { StampComponent } from '../Controls/stamp/stamp.component';
import { DateSignedComponent } from '../Controls/date-signed/date-signed.component';
import { NameComponent } from '../Controls/name/name.component';
import { FirstNameComponent } from '../Controls/first-name/first-name.component';
import { LastNameComponent } from '../Controls/last-name/last-name.component';
import { EmailAddressComponent } from '../Controls/email-address/email-address.component';
import { CompanyComponent } from '../Controls/company/company.component';
import { TextComponent } from '../Controls/text/text.component';
import { CheckBoxComponent } from '../Controls/check-box/check-box.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { TitleContainerComponent } from '../Controls/title-container/title-container.component';
import { ApplicationMenuService } from '../../../containers/layout/application-menu/application-menu.service';
import * as moment from 'moment';
import { ColumnMode, DatatableComponent, numericIndexGetter, SelectionType } from '@swimlane/ngx-datatable';
import { Router } from '@angular/router';
import { fromEvent } from 'rxjs';
import { pairwise, switchMap, takeUntil, filter } from 'rxjs/operators';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { asLiteral } from '@angular/compiler/src/render3/view/util';
import { StrikerComponent } from '../Controls/striker/striker.component';
import { ReactiveBasicComponent } from '../../../containers/form-validations/reactive-basic/reactive-basic.component';
import { getValueFromObject } from 'ngx-bootstrap/typeahead';
import { timeStamp } from 'console';
// import { setTimeout } from 'timers';



@Component({
  selector: 'app-create-documents',
  templateUrl: './create-documents.component.html',
  styleUrls: ['./create-documents.component.scss']
})
export class CreateDocumentsComponent implements OnInit {
  isRightPanel: boolean
  myFiles: any[];
  returnObj: any;
  responeData: any[] = [];
  firstRow: any[] = [];
  currentEditIndex:any;
  @ViewChild('editorCanvas') editorCanvas: ElementRef;
  @ViewChildren('editorCanvas', { read: ViewContainerRef }) entry: QueryList<ViewContainerRef>;
  @ViewChild('dropZone') dropZone: DropzoneComponent;
  @ViewChild('dataTable') dataTable: DatatableComponent;
  @ViewChild('awWizard') awWizard: WizardComponent;
  isdisabled: boolean = true;
  modalRefSign: any;
  isDraw: boolean = false;
  row: [{ name: 'Mairaj', email: 'email@gmail.com' }];
  selectiveColorCode:any=[
    '#F9E400' , '#1EAC4B', '#16ACE0','#593591','#B82062',
    '#F15C24','#AACB38','#18A69B','#1A6BB4',
    '#8B2F8F','#ED146E','#94C1E1','#F59494','#BBC9BD','#CEA9CE',
    '#CD5C5C','#2E4053','#717D7E','#007CFF','#513C71'
   ];
   checkboxes:any;
   lastIndex:any;
   tableIndexvalue:any;
  tableName: string = "Recipients";
  positionLeft: any;
  positionTop: any;
  ColumnMode = ColumnMode;
  SelectionType = SelectionType;
  cx: any;
  canvasEl: any;
  modalRefPlacement: any;
  dataURL: any;
  colorContainer:any;
  @ViewChild('canvasSignatureUser') canvasSignature: TemplateRef<any>
  @ViewChild('modalPlacement') modalPlacement: TemplateRef<any>
  @ViewChild('signaturePadUser') canvas: ElementRef
  componenList = [
    {
      key: 'Signature',
      component: DocumentEditorComponent,
      active: false,
    },
    {
      key: 'CheckBox',
      component: CheckBoxComponent,
      active: false,
    },
    {
      key: 'Company',
      component: CompanyComponent,
      active: false,
    },
    {
      key: 'Email',
      component: EmailAddressComponent,
      active: false,
    },
    {
      key: 'FirstName',
      component: FirstNameComponent,
      active: false,
    },
    {
      key: 'Initials',
      component: InitialsComponent,
      active: false,
    },
    {
      key: 'LastName',
      component: LastNameComponent,
      active: false,
    },
    {
      key: 'Name',
      component: NameComponent,
      active: false,
    },
    {
      key: 'Stamp',
      component: StampComponent,
      active: false,
    },
    {
      key: 'Text',
      component: TextComponent,
      active: false,
    },
    {
      key: 'Title',
      component: TitleContainerComponent,
      active: false,
    },
    {
      key: 'DateSign',
      component: DateSignedComponent,
      active: false,
    },
    {
      key: 'Striker',
      component: StrikerComponent,
      active: false,
    }

  ];
  currentGadget: any;
  gadgetId: any;
  imageCollection: any[] = [];
  isActive: boolean;
  isFinish: boolean = false;
  imageSignature: any;
  constructor(private modalService: BsModalService, public sanitizer: DomSanitizer, private http: HttpClient, public document: Document, private el: ElementRef,
    public overlay: Overlay, private resolver: ComponentFactoryResolver, private ngxService: NgxUiLoaderService, private appservice: ApplicationMenuService,
    private router: Router, private notifiction: NotificationsService) {
  }

  files: File[] = [];

  onSelect(event) {
    this.files.push(...event.addedFiles);

  }
  

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
    this.images = [];
    this.responeData = [];
    this.imageCollection = [];
  }

  ngOnInit(): void {
    this.recipentList = [];
    let sign = localStorage.getItem('signatureNote');
    if (sign) {
      this.imageSignature = sign;
    } else {
      this, this.imageSignature = 'assets/img/profiles/sign.png';
    }
    this.getHeaderTiles();
    // this.finishForm.controls['expireDate'].value=(moment(new Date()).setDate((new Date())).getDate)
    // let sevenDays = new Date();
    // sevenDays.setDate(sevenDays.getDate() + 7);
   
    
  }
  // step 1
  // dropzone
  config = {
    url: 'https://httpbin.org/post',
    thumbnailWidth: 500,
    thumbnailHeight: 300,
    // maxFilesize: 1000000,
    // tslint:disable-next-line: max-line-length
    previewTemplate: `<div class="dz-preview dz-file-preview mb-3"><div class="d-flex flex-row "><div class="p-0 w-30 position-relative"><div class="dz-error-mark"><span><i></i></span></div><div class="dz-success-mark"><span><i></i></span></div><div class="preview-container"><img data-dz-thumbnail class="img-thumbnail border-0" /><i class="simple-icon-doc preview-icon" ></i></div></div><div class="pl-3 pt-2 pr-2 pb-1 w-70 dz-details position-relative"><div><span data-dz-name></span></div><div class="text-primary text-extra-small" data-dz-size /><div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div><div class="dz-error-message"><span data-dz-errormessage></span></div></div></div><a (click)="onDelete($event)" class="remove" data-dz-remove><i class="glyph-icon simple-icon-trash"></i></a></div>`
  };
  onUploadError(event): void {

    console.error(event);
  }
  images: any = [];
  height: number = 500;
  headers: any;

  ExitStep(event) {

    if (event && this.responeData.length > 0 && this.awWizard) {

      this.awWizard.currentStep.canExit = true
    } else {

      this.awWizard.currentStep.canExit = false
    }
  }

  async onFilePost() {

    // const reader = new FileReader();
    // const fileInfo = event[0];

    let token = localStorage.getItem('data')
    let headers = { 'Authorization': token ? 'Bearer' + token : null, 'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundaryOcsc8PEBsifSiUvo' }

    // const body = event[0];
    var apiRoute = this.files.length > 1 ? 'filecoverter/post' : 'filecoverter/multiplePost';
    if (this.files.length > 0) {

      for (let index = 0; index < this.files.length; index++) {
        const element = this.files[index];
        let formData = new FormData();
        formData.append("file", element);
        formData.append("purchaseID", localStorage.getItem('purchaseID'));
        formData.append("userID", localStorage.getItem('userID'));

        this.ngxService.start();

        await this.http.post(environment.apiUrl + 'filecoverter/post', formData).toPromise().then((user: any) => {

          this.ngxService.stop();
          if (user && user.message) {
            this.notifiction.create('Error', user.message,
              NotificationType.Error, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false });
          }
          else if (user) {

            this.responeData.push(user);
            this.isdisabled = false;

            this.awWizard.goToStep(1);
          } else {

            this.ngxService.stop();
            this.isdisabled = true;
          }
        }).catch((error) => {

          if (error) {
            this.awWizard.goToStep(0);
            this.ngxService.stop();
          }
        });
      }

      if (this.responeData) {

        this.responeData.map((i) => {
          if (i.imageCollection.length > 0) {
            i.imageCollection.map((u) => {
              this.imageCollection.push(u);
            })
          }
        })

        this.imageCollection.push(this.responeData[this.responeData.length - 1].folderName);

        await this.http.post(environment.apiUrl + 'filecoverter/getImages', this.imageCollection).toPromise().then((user: any) => {

          if (user) {

          } else {

          }
        }).catch((error) => {
          this.awWizard.goToStep(1);
          if (error) {
            this.ngxService.stop();
          }
        });
      }
    }


  }

  onEdit(id) {
    if (this.awWizard) {
      this.awWizard.goToStep(id);
    }
  }

  photoURL(file: any) {

    return this.sanitizer.bypassSecurityTrustResourceUrl(file);
  }
  // step 1 end

  // step 2
  // recipent working
  recipentsForm = new FormGroup({
    name: new FormControl(''),
    email: new FormControl(''),
    sColorCode: new FormControl(''),
    priority: new FormControl('')
  });

  panelForm = new FormGroup({
    name: new FormControl(''),
    sColorCode: new FormControl('')
  });

  finishForm = new FormGroup({
    Subject: new FormControl(''),
    Message: new FormControl(''),
    expireDate: new FormControl(''),
    day: new FormControl(''),
  });

  recipentList: any = [];
  existingRecipentList: any = []; // = this.recipentList;

  onDelete(event) {

    if (event) {
      
    }
  }
  
  addNewEmail() {
    debugger
    // console.log('This color value is',color);
    // const color= this.recipentsForm.controls['sColorCode'].value;



  // this.lastIndex = this.recipentList.length = 0 ? 0 : (this.recipentList.length);
  //  this.recipentsForm.controls['sColorCode'].setValue(this.selectiveColorCode[this.lastIndex]);


  debugger
    if (!this.recipentsForm.controls['name'].value && !this.recipentsForm.controls['email'].value){

      // && !this.recipentsForm.controls['sColorCode'].value

      this.emailCheck = true;
      this.nameCheck = true;

      // this.sColorCodeCheck = true;

      this.emailValidation = "Email is required!"
    } else if (!this.recipentsForm.controls['name'].value) {

      this.nameCheck = true;
    } else if (!this.recipentsForm.controls['email'].value) { 

      this.emailCheck = true;
      this.emailValidation = "Email is required!"
    }

    // else if (!this.recipentsForm.controls['sColorCode'].value){
    //   this.sColorCodeCheck = true;
    // }

    else if (!this.checkDuplicate()) {
debugger
// this.lastIndex = this.recipentList.length = 0 ? 0 : (this.recipentList.length);

      this.emailCheck = false;
      this.nameCheck = false;
      this.sColorCodeCheck = false;
      this.recipentList.push({ name: this.recipentsForm.value.name, email: this.recipentsForm.value.email,});
      // sColorCode: this.selectiveColorCode[this.lastIndex]
      this.existingRecipentList.push({ name: this.recipentsForm.value.name, email: this.recipentsForm.value.email,sColorCode: this.recipentsForm.value.sColorCode});
      this.modalRef.hide();
      this.recipentsForm.controls['name'].setValue("");
      this.recipentsForm.controls['email'].setValue("");
      this.recipentsForm.controls['sColorCode'].setValue("");
    } else {
      alert("Email should be unique");
    } 
  };
  checkDuplicate(): boolean {
    var exist = this.recipentList.findIndex(x => x.email === this.recipentsForm.controls["email"].value && x.name === this.recipentsForm.controls["name"].value);
    if (exist > 0) {
      return true;
    }
    return false;
  }
  selectRecipent(row) { 
debugger
    // var trs = document.querySelectorAll("tr");
    // 
    // for (var i = 0; i < trs.length; i++) {
    //   
    //   trs[i].addEventListener("click", function () {
    //     
    //     var cn = this.className, thc = " selected", start_idx = cn.indexOf(thc);
    //     
    //     if (start_idx == -1) cn += thc;
    //     else cn = cn.replace(thc, "");
    //     this.className = cn;
    //   });
    // }

    this.recipentList.push({ name: row.name, email: row.email, sColorCode: row.sColorCode });

    this.modalRef.hide();
  };
  setPriority(row) {
    return row.priority = this.recipentsForm.controls["priority"].value;
  };

  modalRef: BsModalRef;
  isPriority: any = false;
  customCheckThis: any;

  openModal(template: TemplateRef<any>): void {

    debugger
    this.getRecipients().then((x: any) => {

      this.modalRef = this.modalService.show(template);

      setTimeout(() => {
        if (this.dataTable) {
          this.dataTable.selected = this.recipentList;
        }
      }, 0);
    });
  };
  openModalAdd(template: TemplateRef<any> ,edit? , i? ): void {
    // this.getRecipients().then((x: any) => {
    this.lastIndex= this.recipentList.length = 0 ? 0 : (this.recipentList.length);
    this.modalRef = this.modalService.show(template);
    if(edit && i>=0){
      this.recipentsForm.controls.email.setValue(edit.email);
      this.recipentsForm.controls.name.setValue(edit.name);
      this.recipentsForm.controls.sColorCode.setValue(edit.sColorCode);
      this.currentEditIndex = i;
    }
    else {
    this.recipentsForm.controls.email.setValue(undefined);
    this.recipentsForm.controls.name.setValue(undefined);
    this.recipentsForm.controls.sColorCode.setValue(undefined);

    this.emailCheck = false;
    this.nameCheck = false;
    // });
    }
    
  };

  // openStriker(template: TemplateRef<any>): void {

  //   this.modalRef = this.modalService.show(template);


  // }
  deleteRecipent(row) {
    debugger
    let index = this.recipentList.findIndex(x => x.email === row.email && x.name === row.name);
    // alert(index);
    this.recipentList.splice(index, 1);
    this.selectedRows.splice(index, 1);
  };
  save() {
    debugger
    if (this.customCheckThis) { } else {
      var i = 1;
      debugger
      this.recipentList.forEach((element, index) => {
        element.priority = ++index;
        i++;
      });
    }
  };

  @ViewChild('basicMenu') public basicMenu: ContextMenuComponent;
  trackByFn(index) {
    return (index);
  }   
  async checkImages() {
    debugger

    if (this.customCheckThis === false && this.recipentList.length > 0) {
      this.recipentList.map(i => {
        if (i.priority) {
          i.priority = undefined;
          return i;
        }
      })
    }
    this.appservice.isOpen = true;
    this.isRightPanel = true;

    var container: any = document.getElementsByClassName('mainContainer')[0];
    if (container) {
      if (this.isRightPanel) {
        container.style.marginRight = '145px';
      } else {
        container.style.marginRight = '50px';
      }
    }

    let count = 0;
    var img = new Image();
    if (this.images.length === 0)
      if (this.imageCollection.length > 0) {

        // for (let ind = 0; ind < this.responeData.length; ind++) {

        // const element = this.responeData[ind];
        this.ngxService.start();
        await this.http.get(environment.apiUrl + 'filecoverter/GetConvertedFiles', { params: { path: this.imageCollection[this.imageCollection.length - 1] } }).toPromise().then((user: any) => {
          this.ngxService.stop();
          if (user) {
            user.forEach(ele => {
              this.images.push({ Id: "can" + count, base64: ele, childrens: [], imageName: this.imageCollection[this.imageCollection.length - 1] });
              count++;
            });
          }
        }).catch((error) => {
          if (error) {
            this.ngxService.stop();
          }
        });

        // }
      }
  }

  setDays(value) {

    // if (this.finishForm.controls['expireDate'].value) {

    //   let val = new Date(this.finishForm.controls['expireDate'].value);

    //   val = new Date( val.getFullYear(), val.getMonth(),val.getDate() + Number(value) + 1);

    //   this.finishForm.controls['expireDate'].setValue(val.toISOString().substring(0, 10))
    // }
    let formateDate=moment();
    this.finishForm.controls['expireDate'].setValue(formateDate.add(value,'days').format('YYYY-MM-DD'));
  }

  PanelState() {
    debugger
    this.isRightPanel = !this.isRightPanel;
    this.appservice.isOpen = this.appservice.isOpen;
    let formateDate=moment().add(7,'days').format('YYYY-MM-DD');
    if (!this.finishForm.controls['expireDate'].value) this.finishForm.controls['expireDate'].setValue(formateDate)
    this.finishForm.controls.day.setValue(7);
    setTimeout(() => {

      var panel: any = document.getElementsByClassName('app-menu')[0];
      var container: any = document.getElementsByClassName('mainContainer')[0];

      if (this.isRightPanel) {

        if (panel) {

          panel.style.display = "block";

          if (container) {

            container.style.marginRight = '145px';
          } else {

            container.style.marginRight = '50px';
          }
        }
        else {

          panel.style.display = "none"
        }
      } else {

        if (panel)
          panel.style.display = "none"

        var container: any = document.getElementsByClassName('mainContainer')[0];

        if (container) {

          container.style.marginRight = '50px';
        }
      }
    }, 100);

  }

  myDrawingFunction(event, e) {
    // console.log("The event is",event);
    // console.log("Th e value is",e);
    
    
    debugger
    this.ControlPlace(event, e);
    // console.log("This is Control Place",this.ControlPlace) ;
    
    //this.AddStyle(event)
  }

  async getRecipients() {
    let token = localStorage.getItem('data')
    let userID = localStorage.getItem('userID')
    let headers = { 'Authorization': 'Bearer ' + token }
    this.ngxService.start();
    await this.http.get(environment.apiUrl + 'Document/getRecipients/' + userID, { headers: headers }).toPromise().then((user1: any) => {
      this.ngxService.stop();
      if (user1) {
        this.row = user1;
      }
    }).catch((error) => {
      if (error) {
        this.ngxService.stop();
      }
    });
  }

  drawToCanvas(nativeURL, canvasId) {
    var canvas = <HTMLCanvasElement>this.el.nativeElement.querySelector(canvasId);
    var img = new Image();
    img.src = nativeURL;
    canvas.style.width = img.width.toString() + 'px';
    canvas.style.height = img.height.toString() + 'px';

  }

  setImageSize(p) {
    var obj = {};
    var img = new Image();

    img.src = p.base64;
    if (img.height >= 705 && img.width >= 705)
    {
    if (img.height < img.width)
    {

    
    obj = {
      //width: img.width.toString() + 'px',
      //height: img.height.toString() + 'px',
      width: '1410px',
      height: '997px',
      'background-image': 'url(' + p.base64 + ')'
    }
  }
  else
  {
    obj = {
      //width: img.width.toString() + 'px',
      //height: img.height.toString() + 'px',
      width: '705px',
      height: '997px',
      'background-image': 'url(' + p.base64 + ')'
    }
  }
}
else
{
  obj = {
    //width: img.width.toString() + 'px',
    //height: img.height.toString() + 'px',
    width: img.height + 'px',
    height: img.width + 'px',
    'background-image': 'url(' + p.base64 + ')'
  }
}
    return obj;
  }

  setLeftDiv(p) {
    var obj = {};
    var img = new Image();

    img.src = p.base64;
    if (img.height < img.width)
    {

    
    obj = {
      //width: img.width.toString() + 'px',
      //height: img.height.toString() + 'px',
      'display': 'none'
    }
  }
  else
  {
    obj = {
      //width: img.width.toString() + 'px',
      //height: img.height.toString() + 'px',
      'display': 'true'
    }
  }
    return obj;
  }
  async activeControls(code) {
debugger
    this.componenList.map((i) => {
      if (i.key === code) {
        i.active = true;
      } else {
        i.active = false
      }
      return i;
    })

  }

  nextPosition: number = 0;
  componentRef: any;
  public viewRefs: QueryList<ViewContainerRef>;
  @ViewChild("controlDIV", { read: ViewContainerRef }) private vcr: ViewContainerRef;
  ControlPlace(event, target) {
    if (event && target) {
      var c: any = document.getElementById(event.Id)
      const rect = c.getBoundingClientRect()
      var x = target.clientX - rect.left
      var y = target.clientY - rect.top
      debugger
      this.positionLeft = x + 'px';
      this.positionTop = y + 'px'; //Nadeem
      var currenGadget = this.componenList.filter(i => i.active === true)[0];
      var pageId = 0;
      if (this.entry) {
        this.entry.forEach(element => {
          if (element) {
            let id = element.element.nativeElement.id;
            if (id === event.Id) {
              this.isActive = true;
              pageId++;
              const factory = this.resolver.resolveComponentFactory(currenGadget.component);
              let component = element.createComponent(factory)
              component.instance.left = x + 'px'
              component.instance.top = y + 'px'
              this.nextPosition++;
              component.instance.parentId = event.Id;
              component.instance.subId = currenGadget.key + this.nextPosition;
              component.instance.ImageX = target.clientX;
              component.instance.ImageY = target.clientY;
              component.instance.title = this.assignTitle();
              // component.instance.titleColor = this.assignTitleColor();
              component.instance.id = currenGadget.key + this.nextPosition;
              component.instance.onAdd.subscribe((ev) => {
                this.Add(ev);
              });
              component.instance.onRemove.subscribe((ev) => {
                this.Remove(ev);
              });
              component.instance.onRightClick.subscribe((ev) => {
                this.onRightClick(ev);
              });
              component.instance.onCopy.subscribe((ev) => {
                this.Copy(ev);
              });
              component.instance.changePosition.subscribe((ev) => {
                this.changePosition(ev);
              });
              this.componenList.filter(i => i.key === currenGadget.key)[0].active = false;
              let obj = {
                id: component.instance.id,
                subId: component.instance.subId,
                type: component.instance.componentName,
                recipientName: this.assignTitle(),
                recipientColor: this.assignscodecolor(),
                email: this.assignEmail(),
                xAxis: x,
                yAxis: y,
                priority: this.assignPriority(),
                pageId: pageId,
                imageName: this.responeData[0].folderName,
                recipients: '',
                Subject: '',
                Message: '',
                expireDate: '',                
                sColorCode: this.assignscodecolor(),
              }
              debugger
              this.images.filter(i => i.Id === event.Id)[0].childrens.push(obj);
              this.Add(component)
            } else {
              pageId++;
            }
          }
        });
      }
    }
  }
  changePosition(ev: any) {
 
    if (ev && this.images) {

      this.images.map((i) => {
        if (i.Id === ev.parentId) {
          if (i.childrens.length > 0) {
            i.childrens.map((u) => {

              if (u.subId === ev.subId) {

                u.xAxis = ev.xAxis;
                u.yAxis = ev.yAxis;
                return u;
              }
            })
          }
        }
        return i;
      })
    }
  }
  Copy(ev) {
    let pageId = 0;
    if (ev && this.entry) {
      var x, y = 0;
      var count: number = 1;
      this.entry.forEach((element: any) => {
        if (element) {
          let id = element.element.nativeElement.id;
          if (id !== ev.parentId) {
            pageId++;
            var current: any = document.getElementById(id)
            const rectCurrent = current.getBoundingClientRect()

            x = Number(ev.xAxis)
            y = Number(ev.yAxis)

            let currentComponent = this.componenList.filter(i => i.key === ev.componentName)[0];
            const factory = this.resolver.resolveComponentFactory(currentComponent.component);
            let component = element.createComponent(factory)

            component.instance.left = x + 'px'
            component.instance.top = y + 'px'
            this.nextPosition++;
            component.instance.parentId = id;
            component.instance.title = ev.title;
            component.instance.titleColor = ev.titleColor;
            component.instance.id = currentComponent.key + this.nextPosition;
            component.instance.subId = currentComponent.key + this.nextPosition;
            component.instance.onAdd.subscribe((ev) => {
              this.Add(ev);
            });
            component.instance.onRemove.subscribe((ev) => {
              this.Remove(ev);
            });
            component.instance.onRightClick.subscribe((ev) => {
              this.onRightClick(ev);
            });
            component.instance.onCopy.subscribe((ev) => {
              this.Copy(ev);
            });
            component.instance.changePosition.subscribe((ev) => {
              this.changePosition(ev);
            });
            debugger
            let obj = {
            
              id: component.instance.id,
              subId: component.instance.subId,
              type: component.instance.componentName,
              recipientName: this.panelForm.controls.name.value ? this.panelForm.controls.name.value : this.assignTitle(),
              recipientColor: this.panelForm.controls.value ? this.recipentList.filter(i => i.name === this.panelForm.controls.name.value)[0].sColorCode :
              this.assignscodecolor(),
              email: this.panelForm.controls.name.value ? this.recipentList.filter(i => i.name === this.panelForm.controls.name.value)[0].email : this.assignEmail(),
              xAxis: x,
              yAxis: y,
              priority: this.panelForm.controls.name.value ? this.recipentList.filter(i => i.name === this.panelForm.controls.name.value)[0].priority : this.assignPriority(),
              pageId: pageId,
              imageName: this.responeData[0].folderName,
              recipients: '',
              Subject: '',
              Message: '',
              expireDate: '',
              sColorCode: this.panelForm.controls.value ? this.recipentList.filter(i => i.name === this.panelForm.controls.name.value)[0].sColorCode :
              this.assignscodecolor(),
            }
            debugger
            this.images.filter(i => i.Id === id)[0].childrens.push(obj);
          } else {
            pageId++;
            this.images.map((u) => {
              if (u.Id === id) {
                if (u.childrens && u.childrens.length && u.childrens.length > 0) {
                  u.childrens.map((j) => {
                    if (j.id === ev.id) {
                      j.recipientName = this.panelForm.controls.name.value ? this.panelForm.controls.name.value : '';
                      j.email = this.panelForm.controls.name.value ? this.recipentList.filter(i => i.name === this.panelForm.controls.name.value)[0].email : '';
                      j.recipientColor = this.panelForm.controls.name.value ? this.recipentList.filter(i => i.name === this.panelForm.controls.name.value)[0].sColorCode : '';
                      j.priority = this.panelForm.controls.name.value ? this.recipentList.filter(i => i.name === this.panelForm.controls.name.value)[0].priority : '';
                      return j;
                    }
                  })
                }
              }
              return u;
            })
          }
        }
      });
    }
  }

  isShow: boolean;
  onRightClick(ev) {

  }
 
  gadetCollection: any[] = [];

  placementSaving() {
    debugger
    
    let colorFromPanel = this.recipentList.filter((i) => {
      if(i.name === this.panelForm.controls.name.value) {
    
        return i.sColorCode;
    
       }})

   
    let check: any = document.getElementById('placementCheckBox');
    this.currentGadget.title = this.panelForm.controls.name.value;
    this.currentGadget.titleColor = colorFromPanel[0].sColorCode;

    var currentTitle = document.getElementById(this.currentGadget.id);
    console.log("This is our Current Title",currentTitle);

    console.log("This is our current color", this.currentGadget.titleColor);
    
   
    

    

    if (currentTitle) {

    }
    if (check && check.checked === true) {

      this.Copy(this.currentGadget);
    }
    this.isOpenSwitcher = !this.isOpenSwitcher;

    this.panelToggleClass = this.isOpenSwitcher
      ? 'Panel shown'
      : 'Panel hidden';

    if (this.panelForm.controls.name.value) {
      this.images.map((i) => {
        if (i.Id === this.gadgetParent) {
          if (i.childrens && i.childrens.length && i.childrens.length > 0) {
            i.childrens.map((u) => {
              if (u.id === this.gadgetId) {
                u.recipientName = this.panelForm.controls.name.value ? this.panelForm.controls.name.value : '';
                u.recipientColor = this.panelForm.controls.name.value ? this.recipentList.filter(i => i.name === this.panelForm.controls.name.value)[0].sColorCode : '';
                u.email = this.panelForm.controls.name.value ? this.recipentList.filter(i => i.name === this.panelForm.controls.name.value)[0].email : '';
                u.priority = this.panelForm.controls.name.value ? this.recipentList.filter(i => i.name === this.panelForm.controls.name.value)[0].priority : '';
              }
              return u;
            })
          }
        }
        return i;
      })
    }
    // if (this.panelForm.controls.sColorCode.value) {
    //   this.images.map((i) => {
    //     if (i.Id === this.gadgetParent) {
    //       if (i.childrens && i.childrens.length && i.childrens.length > 0) {
    //         i.childrens.map((u) => {
    //           if (u.id === this.gadgetId) {
    //             u.recipientName = this.panelForm.controls.name.value ? this.panelForm.controls.name.value : '';
    //             u.recipientColor = this.panelForm.controls.sColorCode.value ? this.panelForm.controls.sColorCode.value : '';
    //             u.email = this.panelForm.controls.name.value ? this.recipentList.filter(i => i.name === this.panelForm.controls.name.value)[0].email : '';
    //             u.priority = this.panelForm.controls.name.value ? this.recipentList.filter(i => i.name === this.panelForm.controls.name.value)[0].priority : '';
    //           }
    //           return u;
    //         })
    //       }
    //     }
    //     return i;
    //   })
    // }
  }

  onClick(indexOfelement:any){

   this.tableIndexvalue=indexOfelement;
  }

  assignTitle() {
    debugger
    if (this.recipentList.length > 0) {
      let periortyPerson = this.recipentList.filter(i => i.priority !== undefined && i.priority === 1)[0];
      console.log("What value i get",periortyPerson);
      
      if (periortyPerson) {
        return periortyPerson.name 
      } else {
        return this.recipentList[0].name;
      }
    }

    // && periortyPerson.color
    
  }
  
  //storeColor:any[]=[{color:''}]
  assignTitleColor(e) {
   debugger
   //alert(e)
    if (this.recipentList.length > 0) {
    // debugger
    // if (this.recipentList.length > 0)
    // debugger
    //   this.recipentList.map(i => {
    //     debugger
    //     if (this.recipentList.filter(o => o.sColorCode == i.sColorCode).length === 0)
    //       this.recipentList.push(i)
    //   })
      //if (this.recipentList.filter(o => o.email === i.email && o.name === i.name && o.sColorCode == i.sColorCode).length === 0)
           //this.recipentList.push(i)
       // let colorCode = this.recipentList.filter(i => i.sColorCode === this.panelForm.controls.sColorCode.value)
       // return colorCode
       //let list = this.recipentList.filter(i => i.sColorCode === this.panelForm.controls.sColorCode.value)
       //let  colorCode  = this.recipentList.filter(i => i.sColorCode === this.panelForm.controls.sColorCode.value)
      //  debugger
      //let periortyColor = this.existingRecipentList.filter(i => i.sColorCode === this.panelForm.controls.sColorCode.value);
      let periortyColor = this.recipentList.filter(i => i.sColorCode ===  i.sColorCode)[0];
     
      //alert(periortyColor)

      // var priorityColor = periortyColor.sColorCode;
      // console.log("This is our new Priority Color", priorityColor);
      

      // console.log("this is our priority",);
     
      // periortyColor=this.recipentsForm.controls['sColorCode'].value;
      // console.log("this is our priority color",periortyColor);
      



      if (periortyColor) {
        debugger
        return periortyColor[0].sColorCode
        
      } else {
        debugger
        //this.storeColor=this.recipentList.sColorCode;
        //alert(this.storeColor)
        
        return this.recipentList[0].sColorCode;
       
      }
    }
  }

  assignEmail() {
    debugger

    if (this.recipentList.length > 0) {

      let periortyPerson = this.recipentList.filter(i => i.priority !== undefined && i.priority === 1)[0];
      if (periortyPerson) {

        return periortyPerson.email
      } else {

        return this.recipentList[0].email;
      }
    }
  }

  assignscodecolor() {

    debugger
    if (this.recipentList.length > 0) {
      let scodecolor = this.recipentList.filter(i => i.priority !== undefined && i.priority === 1)[0];
      if (scodecolor) {
        return scodecolor.sColorCode
      } else {
        return this.recipentList[0].sColorCode;
      }
    }
  }
  assignPriority() {

    debugger

    if (this.recipentList.length > 0) {

      let periortyPerson = this.recipentList.filter(i => i.priority !== undefined && i.priority === 1)[0];
      if (periortyPerson) {

        return periortyPerson.priority
      } else {

        return 0;
      }
    }
  }

  panelClose() {
    this.isOpenSwitcher = !this.isOpenSwitcher;
    this.panelToggleClass = this.isOpenSwitcher
      ? 'Panel shown'
      : 'Panel hidden';
  }
  gadgetName: any;
  gadgetParent: any;
  gadgetColor: any;
  Add(ev: any) {
   debugger
    this.isOpenSwitcher = !this.isOpenSwitcher;
    this.panelToggleClass = this.isOpenSwitcher
      ? 'Panel shown'
      : 'Panel hidden';

    this.gadgetName = ev.event ? ev.event.componentName : ev.instance.componentName;
    this.gadgetId = ev.event ? ev.event.id : ev.instance.id;
    this.gadgetParent = ev.event ? ev.event.parentId : ev.instance.parentId;

    let assign = ev.event ? ev.event.title : ev.instance ? ev.instance.title : this.assignTitle()
    debugger
   let assignColor = ev.event ? ev.event.titleColor : ev.instance ? ev.instance.titleColor : this.assignTitleColor(1)
    debugger
    this.panelForm.controls['name'].setValue(assign)
    debugger
    this.panelForm.controls['sColorCode'].setValue(assignColor)
    
  
    this.currentGadget = ev.event ? ev.event : ev.instance;

    if (ev.ev) ev.ev.stopPropagation();
  }

  async setAssignTo(ev) {

    if (ev) {

    }
  }
  async setAssignTo1(ev) {

    if (ev) {

    }
  }

  async focusOut() {
    if (this.finishForm.controls.Subject.value && this.finishForm.controls.Message.value) {
      this.isFinish = true;
    }
    else if (!this.finishForm.controls.Subject.value || !this.finishForm.controls.Message.value) {
      this.isFinish = false;
    }
  }

  async Finish() {
  debugger
    var array: any[] = [];
    if (this.images.length > 0) {
      this.images.map((y) => {
        debugger
        if (y.childrens && y.childrens.length && y.childrens.length > 0) {
          debugger
          y.childrens.map((item) => {            
            item.Subject = this.finishForm.controls.Subject.value;
            item.Message = this.finishForm.controls.Message.value;
            item.expireDate = this.finishForm.controls.expireDate.value;
            item.xAxis = Math.round(item.xAxis);
            item.yAxis = Math.round(item.yAxis + 45); //Nadeem
            item.imageName = y.imageName;
            item.Fk_PurchaseID = localStorage.getItem('purchaseID');
            item.createdBy = localStorage.getItem('userID');
            
            array.push(item);
            return item;
          })

        }
        return y;
      })

      this.gadetCollection = [...array];

      let token = localStorage.getItem('data')
      let headers = { 'Authorization': 'Bearer ' + token }
      this.ngxService.start();
      debugger
      await this.http.post(environment.apiUrl + 'Document/saveDocument', this.gadetCollection, { headers }).toPromise().then((user: any) => {
        debugger
        this.ngxService.stop();
        if (user && user.message) {
          this.notifiction.create('Success', user.message,
            NotificationType.Error, { theClass: 'outline primary', timeOut: 16000, showProgressBar: false });
          this.awWizard.goToStep(3);
          this.ngxService.stop();
        }
        else if (user) {

        } else {

          this.awWizard.goToStep(3);
          this.ngxService.stop();
        }
      }).catch((error) => {
        if (error) {
          this.awWizard.goToStep(3);
          this.ngxService.stop();
        }
      });
    }
    setTimeout(() => {
     this.nevigateScreen();
     
  },2000);
  }

  @HostListener('document:click', ['$event'])
  handleDocumentClick(event): void {
    // this.isOpenSwitcher = false;
    // this.panelToggleClass = 'panel hidden';
  }
  Remove(ev) {
    let col: any[] = [];
    if (ev) {
      this.isOpenSwitcher = false;

      this.panelToggleClass = this.isOpenSwitcher
        ? 'Panel shown'
        : 'Panel hidden';
      var context = document.getElementById(ev.id);
      if (context) {
        context.remove();
        this.images.map((i) => {
          if (i.Id === ev.parentId) {
            if (i.childrens.length > 0) {
              i.childrens = i.childrens.filter(o => o.id !== ev.id);
            }
            if (i.childrens.length > 0) {
              col = i.childrens;
            }
          } else {
            if (i.childrens.length > 0) {
              col = i.childrens;
            }
          }
        })
        if (col.length === 0)
          this.isActive = false;
      }
    }
  }
  selectedRows = [];
  columns: any[] = [{ name: 'Name' }, { name: 'Email', width: 220 },
  {
    prop: 'selected',
    name: '',
    sortable: false,
    canAutoResize: false,
    draggable: false,
    resizable: false,
    headerCheckboxable: true,
    checkboxable: true,
    width: 30
  },
  ];

  async onSelection(event) {

    if (event) {
      this.selectedRows = [...event.selected];
   
    }
  }

  SaveSections() {
    debugger

    //  let val =this.lastIndex = 0 ? 1 : this.lastIndex + 1; 
     // let index = 0;
    // if (this.selectedRows.length >= 0){
    //   for (let yindex=0; yindex< this.selectedRows.length; yindex++) { 
    //     this.selectedRows[yindex].sColorCode=this.selectiveColorCode[yindex + val] ;
    //   }
    // }
    // }
      // else{
      //   this.selectedRows[yindex].sColorCode=this.selectiveColorCode[index];
      // }
    
    //  alert(this.selectedRows.length);
   //     for (let yindex=0; yindex< this.selectedRows.length; yindex++) {
     
   // if(this.recipentList.length>0){
   //         debugger
       
   //    //   alert(this.selectiveColorCode[index]);  
   //  //       if(this.selectedRows[yindex].sColorCode=this.selectiveColorCode[index]){
   //         this.selectedRows[yindex].sColorCode=this.selectiveColorCode[index];
   //       //      alert(this.selectedRows[yindex].sColorCode);
    //     //     }
    //     //     else{
   //     //       debugger
    //     //       alert('1' + this.selectedRows[yindex].sColorCode);
   //     //       alert('1' + this.selectiveColorCode[index]); 
   //     //     this.selectedRows[yindex].sColorCode="#Bd7887";
   //     // }
    
   //     }
   //     index++;
   //   }  
   //}
   // if((checkRow as Boolean)==true)
   debugger
    //  alert(this.selectedRows.length);
     //this.selectedRows = []; 
    //alert(this.selectedRows.length);
      this.selectedRows.map(i => {
        debugger
        if (this.recipentList.filter(o => o.email === i.email && o.name === i.name).length === 0)
        // && o.sColorCode == i.sColorCode
          this.recipentList.push(i)
      })
     // this.selectedRows==null;
      this.selectedRows = [];
    this.modalRef.hide();
  }
  // getValue(){
  //   this.checkboxable = true;
  // }

  panelToggleClass: any = 'Panel';  
  isOpenSwitcher: boolean;

  dateChange(ev) {
    let nextWeekDate= ev;
    console.log(nextWeekDate);
    let show=(nextWeekDate + 7);
    console.log(show);
  }

  nevigateScreen() {
    this.files = [];
    this.images = [];
    this.imageCollection = [];
    this.responeData = [];
    this.recipentList = [];
    this.selectedRows = [];
    this.finishForm.controls['Subject'].setValue(undefined);
    this.finishForm.controls['Message'].setValue(undefined);
    this.finishForm.controls['day'].setValue(undefined);
    this.finishForm.controls['expireDate'].setValue((new Date()).toISOString().substring(0, 10));
    if (this.awWizard) {
      this.awWizard.goToStep(0);
    }
  }
  nameCheck: boolean = false;
  emailCheck: boolean = false;
  sColorCodeCheck: boolean = false;
  emailValidation: string = 'Email is required.';
  async ValidateEmail(ev) {

    if (this.recipentsForm.controls.email.value) {
      var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

      if (this.recipentsForm.controls.email.value.match(mailformat)) {

        this.emailCheck = false;
        return false;
      }
      else {

        this.emailValidation = 'You have entered an invalid email address!'
        this.emailCheck = true;
        return true;
      }
    } else {
      this.emailValidation = 'Email is required!'
      this.emailCheck = true;
    }
  }

  async nameValidator() {
    if (this.recipentsForm.controls.name.value) {
      this.nameCheck = false;
    } else {
      this.nameCheck = true;
    }
  }

  async getHeaderTiles() {
    let token = localStorage.getItem('data')
    let userID = localStorage.getItem('userID')
    let headers = { 'Authorization': 'Bearer ' + token }
    this.ngxService.start();

    await this.http.get(environment.apiUrl + 'Document/getHeaderTiles?' + 'userid=' + userID, { headers: headers }).toPromise().then((user1: any) => {

      this.ngxService.stop();
      if (user1) {
        this.firstRow = user1;
      }
    }).catch((error) => {

      if (error) {
        this.ngxService.stop();
      }
    });
  }
  modalOpen() {

    this.modalRefSign = this.modalService.show(this.canvasSignature);
    debugger
    setTimeout(() => {

      this.canvasEl = document.getElementById('signaturePadUser');
      this.cx = this.canvasEl.getContext('2d');
      this.isDraw = false;
      this.cx.lineWidth = 5;
      this.cx.lineCap = 'round';
      this.cx.strokeStyle = '#000';
      this.captureEvents(this.canvasEl);

    }, 0);
  }
  onFIleUpload(ev) {
    if (ev) {
      var upload = document.getElementById('imgupload').click();
    }
  }
  getFileDetails(e: any) {

    var img = new Image();

    if (this.cx) {
      img.onload = async () => {

        this.cx.drawImage(img, 0, 0); // Or at whatever offset you like

        img.width = img.width / 5;

        img.height = img.height / 5;

      };

      img.src = URL.createObjectURL(e.target.files[0]);

    }
  }
  async onSubmit() {
    if (this.canvasEl) {
      this.dataURL = this.canvasEl.toDataURL("image/png");
      if (this.dataURL) {

        let id = Number(localStorage.getItem('userID'));

        let baseUrl = environment.apiUrl + 'Registration/changeSignature';

        let body = {
          id: id,
          signatureNote: this.dataURL && this.isDraw ? this.dataURL : ''
        }

        await this.http.post(baseUrl, body).toPromise().then((user: any) => {

          if (user === "1") {
            this.imageSignature = this.dataURL
            localStorage.setItem('signatureNote', this.dataURL)
            this.modalRefSign.hide();
            this.notifiction.create('Success', "Signature Updated.",
              NotificationType.Success, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false });
          } else {

            // this.modalRefSign.hide();
          }
        }).catch((error) => {

          this.notifiction.create('Error', error.message,
            NotificationType.Error, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false });
          // this.modalRefSign.hide();
        });
      }
    }
  }
  modalOpenPlacement(template: TemplateRef<any>) {

    this.modalRefPlacement = this.modalService.show(template);
  }
  Undo(ev) {
    if (ev && this.cx) {
      this.isDraw = false;
      this.cx.clearRect(0, 0, 450, 300);
    }
  }
  drawOnCanvas(prevPos: { x: number, y: number }, currentPos: { x: number, y: number }) {
    debugger
    if (!this.cx) {
      this.isDraw = false;
      return;
    }
    this.cx.beginPath();
    if (prevPos) {
      this.cx.moveTo(prevPos.x, prevPos.y); // from
      this.cx.lineTo(currentPos.x, currentPos.y);
      this.cx.stroke();
      this.isDraw = true;
    }

  }
  captureEvents(canvasEl: HTMLCanvasElement) {
    debugger 
    // this will capture all mousedown events from the canvas element
    fromEvent(canvasEl, 'mousedown')
      .pipe(
        switchMap((e) => {
          // after a mouse down, we'll record all mouse moves
          return fromEvent(canvasEl, 'mousemove')
            .pipe(
              // we'll stop (and unsubscribe) once the user releases the mouse
              // this will trigger a 'mouseup' event    
              takeUntil(fromEvent(canvasEl, 'mouseup')),
              // we'll also stop (and unsubscribe) once the mouse leaves the canvas (mouseleave event)
              takeUntil(fromEvent(canvasEl, 'mouseleave')),
              // pairwise lets us get the previous value to draw a line from
              // the previous point to the current point    
              pairwise()
            )
        })
      )
      .subscribe((res: [MouseEvent, MouseEvent]) => {
        const rect = canvasEl.getBoundingClientRect();
        // previous and current position with the offset
        const prevPos = {
          x: res[0].clientX - rect.left,
          y: res[0].clientY - rect.top
        };
        const currentPos = {
          x: res[1].clientX - rect.left,
          y: res[1].clientY - rect.top
        };
        // this method we'll implement soon to do the actual drawing
        debugger 
        this.drawOnCanvas(prevPos, currentPos);
      });
  }

  async SaveInternal() { }

  colorAssign(){
    // debugger 
    // alert(this.recipentList.length)
    // this.lastIndex = this.recipentList.length = 0 ? 0 : (this.recipentList.length)

    let colorIndex = 0;

    for (let index = 0; index < this.recipentList.length; index++) {
      this.recipentList[index].sColorCode = this.selectiveColorCode[colorIndex];
     if (colorIndex==20) {
        colorIndex=0;
        
      }else{
        colorIndex++;
      }
 
    }

     
    // for (let index = 0; index < this.selectiveColorCode.length; index++) {

     // this.recipentsForm.controls['sColorCode'].setValue(this.selectiveColorCode[this.lastIndex]);
       // this.recipentsForm.controls['sColorCode'].setValue(this.selectiveColorCode[this.lastIndex]);
    // this.recipentList.push({sColorCode : this.recipentsForm.value.sColorCode});
    // this.lastIndex++;
    // for (let index = 0; index < this.recipentList.length; index++) {
    //  this.recipentsForm.controls['sColorCode']=this.selectiveColorCode[index];
    // } 
  }

}

