import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root',
})
export class ApplicationMenuService {

    public isOpen: boolean = true;

    isOpenPanel(ev: boolean){
        return ev;
    }

}