import { CdkDragEnd } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Component({
    selector: 'text',
    templateUrl: 'text.component.html',
    styleUrls: ['text.component.scss']
})
export class TextComponent {
    componentName = 'Text';
    @Input()
    left: any;

    @Input()
    top: any;

    @Input()
    id: any;

    @Input()
    parentId: any;

    ImageX: any;
    ImageY: any;

    @Input()
    type: number = 1;

    @Input()
    titleColor: any;

    @Output()
    onClick: EventEmitter<any> = new EventEmitter<any>();

    click(){
        debugger
       this.onClick.emit(this);
    }

    @Output()
    onAdd: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    onRemove: EventEmitter<any> = new EventEmitter<any>();
    @Output()
    onCopy: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    onRightClick: EventEmitter<any> = new EventEmitter<any>();
    positionTop: any;

    Copy() {
        this.status = false;
        
        this.onCopy.emit(this);
    }

    xAxis: number;
    yAxis: number;
    ngAfterViewInit() {

        if (this.top && this.left) {
            this.xAxis = Number(this.left.split('px')[0]);
            this.yAxis = Number(this.top.split('px')[0]);
        }

    }
    status: boolean;
    onright(ev) {

        if (ev) {
            if (this.top) {
                
                let p = Number(this.top.split('px')[0]);
                
                this.positionTop = (p + 70) + 'px'
                
            }
            let v = ev;
            ev.preventDefault();
            this.status = true;
            
            this.onRightClick.emit(v);
        }
    }

    Add(ev) {

        let obj = {
            id: this.id,
            parentId: this.parentId,
            ev: ev,
            event: this
        }
        this.status = true;
        this.onAdd.emit(obj)
    }

    Remove(ev) {

        let obj = {
            id: this.id,
            parentId: this.parentId
        }
        this.onRemove.emit(obj)
    }

    @HostListener("document:click", ["$event"]) clickedOutside(event: any) {
        this.status = false;
    }

    @Input()
    subId: any;

    @Input()
    imageSource: any;

    @Input()
    DocPageId: any;

    @Output()
    changePosition: EventEmitter<any> = new EventEmitter<any>();
    dropped($event: CdkDragEnd) {
        if ($event) {
            const { offsetLeft, offsetTop } = $event.source.element.nativeElement;
            const { x, y } = $event.distance;
            this.xAxis = this.xAxis + x;
            this.yAxis = this.yAxis + y;
            this.changePosition.emit(this)
        }
    }

    @Input()
    title: any;


    @Input()
    isDisabled: boolean = true;

    async ngOnInit() {
        this.yAxis = Number(this.top.split('px')[0]);
        if (this.type === 2 || this.type === 3) {
            this.top = this.yAxis + 40 + 'px'
        }
    }

    AddStyle(ev){
     
        return{
            'background-color':this.titleColor
        }
    }
}
